.mainContainerStyle {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  height: 100vh;
  /* background-image: url("../../../../assets/images/eventsMainBgImg.jpg"); */
  /* background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  background: linear-gradient(to bottom right, #ebf4ff, #ffffff, #f3e8ff);
}

.titleStyle {
  font: normal 300 36px/26px "Medium", sans-serif;
  color: var(--white);
}

.rightBlockStyle {
  width: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  background-color: var(--white);
  padding: 20px;
  border-radius: 6px;
  border: 2px solid #d4d0d0;
}

.eventsTitleStyle {
  font-family: "Lobster", cursive;
  font-size: 3rem;
  color: #0ff;
  text-shadow: 0 0 5px #00f, 0 0 10px #0ff, 0 0 20px #0ff, 0 0 30px #00f;
  align-self: self-start;
}

.loginInfoStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: start;
}

.loginAuthErrorStyle {
  font: normal 500 14px/24px "Medium", sans-serif;
  color: red;
  text-align: center;
}
.reverificationAndForgotContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottomBlockStyle {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  align-items: center;
  gap: 32px;
}
.imageBlockStyle {
  width: 500px;
  height: 400px;
}

.imageStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.inputBlockStyle {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.inputStyle {
  max-width: 100%;
  width: 400px;
}
.forgetPasswordStyle {
  font: normal 500 14px/24px "Medium", sans-serif;
  text-decoration: none;
  align-self: flex-end;
}
.loginTextStyle {
  font: normal 500 24px/24px "Medium", sans-serif;
}

.signupPromptStyle {
  font: normal 500 14px/24px "Medium", sans-serif;
}

.loginSubTextStyle,
.loginPromptTextStyle,
.loginPromptSubTextStyle,
.orStyle {
  font: normal 300 16px/24px "Light", sans-serif;
}
.loginPromptSubTextStyle {
  text-decoration: none;
  border-bottom: 1px solid var(--blue);
  font: normal 500 16px/24px "Medium", sans-serif;
  color: var(--blue);
  cursor: pointer;
}

.loginBtnStyle {
  width: 100%;
}

.orBlockStyle {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.underlineStyle {
  background-color: #888888;
  width: 90px;
  height: 1px;
}

.googleIconStyle {
  font-size: 40px;
  margin: auto;
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 50%;
  padding: 5px;
}

/* Modal Styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  background-color: #4c92dd;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  background: white;
  padding: 30px 20px;
  border-radius: 8px;
  max-width: 600px;
  position: relative;
}

.closeButton {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 24px;
  cursor: pointer;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0 5px;
}

.modalInput {
  width: 100%;
  padding: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.backButton {
  align-self: flex-start;
  color: black;
  padding: 10px 15px;
  border-radius: 6px;
  border: none;
}
.backButton:hover {
  background: gray;
}

.modalButton {
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  width: 100%;
  font: normal 16px/24px "Regular", sans-serif !important;
}

.modalButton:hover {
  background-color: #0056b3;
}

.forgotLoader {
  display: block;
  margin: 16px auto;
}

.forgotHeadingStyle {
  font: normal 300 24px/24px "Light", sans-serif;
}

.forgotSubTextStyle {
  font: normal 300 16px/24px "Light", sans-serif;
}

.resetMessage {
  margin-top: 10px;
  color: #ff0000; /* Adjust color as needed */
}

/* Media Query Start */
@media screen and (max-width: 1280px) {
  .mainContainerStyle {
    max-width: 1280px;
    padding: 24px;
  }

  .imageBlockStyle {
    width: 300px;
    height: 300px;
  }
  .inputStyle {
    width: 300px;
  }
}

@media screen and (max-width: 1024px) {
  .imageBlockStyle {
    width: 300px;
    height: 300px;
  }
}

@media screen and (max-width: 768px) {
  .mainContainerStyle {
    gap: 24px;
  }
  .imageBlockStyle {
    display: none;
  }

  .titleStyle {
    font: normal 300 24px/24px "Light", sans-serif;
  }
  .inputBlockStyle {
    gap: 16px;
  }
  .modalContent {
    max-width: 500px;
  }
}

@media screen and (max-width: 530px) {
  .bottomBlockStyle,
  .rightBlockStyle,
  .inputStyle {
    width: 100%;
  }
  .modalContent {
    max-width: 400px;
  }
}

@media screen and (max-width: 430px) {
  .mainContainerStyle {
    gap: 16px;
  }

  .inputStyle {
    width: 100%;
  }

  .underlineStyle {
    width: 60px;
  }
  .modalContent {
    max-width: 360px;
  }
}

/* Media Query End */
