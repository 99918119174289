.container {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.selectContainer {
  margin-bottom: 10px;
}

.inputLabel {
  display: block;
  margin-bottom: 5px;
}

.textInput {
  flex: 1; /* Allow inputs to take equal space */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.textInput::placeholder {
  color: #aaa;
}

.date {
  font-weight: 600;
  border-top: 2px solid;
  border-bottom: 2px solid;
  margin: 4px 0px;
  padding: 2px 5px;
}
/*nothing*/
.createEventTitleStyle {
  font: normal 24px/24px "Regular", sans-serif !important;
}
.inputCss {
  color: #007bff;
}

.tableBtnStyle {
  font: normal 12px/24px "Light", sans-serif !important;
}

/*
.youtube_recorded,
.youtube_live {
  width: 100%;
  height: 100%;
} */

.confirmPromptStyle,
.deleteActionBtnStyle {
  font: normal 16px/24px "Medium", sans-serif !important;
}

/* Secure Modal Styles */
.secureModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.secureModalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.secureModalTitle {
  margin-bottom: 16px;
}

.secureModalInputField {
  width: 100%;
  padding: 10px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.errorMessage {
  color: red;
  font-size: 14px;
}

.secureSubmitBtn,
.secureCloseBtn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.secureSubmitBtn {
  background-color: #4caf50;
  color: white;
}
.tablesec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  gap: 20px;
}

.rightSection {
  display: flex;
  align-items: center;
  gap: 12px;
}

.filterbtn {
  padding: 10px 15px;
  border-radius: 3px;
  color: white;
  border: none;
  cursor: pointer;
  width: 100px;
}

.filterContainer {
  background-color: white;
  top: -8px;
  left: 21px;
  border-radius: 50%;
  border: none;
  color: red;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

.secureSubmitBtn:hover {
  background-color: #45a049;
}

.secureCloseBtn {
  background-color: #f44336;
  color: white;
  margin-left: 8px;
}

.secureCloseBtn:hover {
  background-color: #d32f2f;
}
.eventsHeading {
  font-size: 16px;
}
.eventsCount {
  background-color: rgb(133, 133, 133);
  padding: 5px 10px;
  border-radius: 50%;
  font-size: 16px;
  color: ivory;
}

@media (max-width: 600px) {
  .eventsHeading {
    font-size: 12px;
  }
  .eventsCount {
    padding: 2px 6px;
    font-size: 12px;
  }
}
@media (max-width: 450px) {
  .filterbtn {
    width: auto;
  }
}

.dateRangeBtn {
  cursor: pointer;
  padding: 5px 15px;
  color: white;
  border: none;
  border-radius: 5px;
}
.dateRangeBtn:hover {
  background-color: #9ce8a0;
}

.confirmPromptStyle {
  font-weight: 600;
  font-size: 18px;
  color: #333;
  text-align: center;
}
.textField {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
}
.deleteActionBtnStyle {
  text-transform: none;
  font-weight: 600;
  padding: 10px 20px;
}
.cancelBtn {
  color: #757575;
}
.deleteBtn {
  color: #f44336;
}
.inputLabel {
  font-size: 16px;
  color: #555;
  margin-bottom: 8px;
  font-weight: 500;
}
.scrollableContainer {
  width: 100%;
  max-width: 100%;
  padding: 5px 20px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}

@media (max-width: 600px) {
  .scrollableContainer {
    overflow-x: scroll;
  }
}

.filterDialogContainer {
  display: flex;
  flex-direction: column;
  background: white;
  position: absolute;
  z-index: 1;
  right: 0;
  margin-top: 4px;
  width: 350px;
  padding: 10px 14px;
  align-items: flex-start;
  border: 1px solid #a52a2a4a;
  border-radius: 5px;
}
@media (max-width: 600px) {
  .filterDialogContainer {
    left: -209px;
  }
}
.deleteText:hover {
  border: #aaa dashed 1px;
  border-radius: 5px;
}
