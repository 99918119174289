* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


/* More specific selector to override default slick styles */
.slick-prev,
.slick-next {
  display: none !important;
}

/* ::-webkit-scrollbar:horizontal {
  display: none;
}

::-ms-overflow {
  -ms-overflow-style: none;
  scrollbar-width: none;
} 
::-webkit-scrollbar:horizontal {
  display: none;
}

::-webkit-scrollbar {
  width: 10px; 
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: lightgray;
}


* {
  scrollbar-width: thin; 
  scrollbar-color: gray lightgray;
}

::-webkit-scrollbar:horizontal {
  height: 0px; 
}

::-ms-overflow {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */

:root {
  /* primary colors */
  --black: #000;
  --white: #fff;
  --red: red;
  --green: green;
  --blue: blue;

  /* secondary colors */
  --steelGray: #dedede;
}

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Light";
  src: url("./assets/fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "Medium";
  src: url("./assets/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Regular";
  src: url("./assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Semibold";
  src: url("./assets/fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Bold";
  src: url("./assets/fonts/Poppins-Bold.ttf");
}
