.MuiInputBase-input {
  padding: 4px 12px 4px 12px !important;
  height: 45px !important;
  width: 87%;
}

.MuiFormHelperText-root {
  color: var(--red) !important;
  font: normal 400 12px/24px "Regular", sans-serif !important;
  margin: 2px !important;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 46px !important;
}

@media screen and (max-width: 1440px) {
  .react-tel-input .form-control {
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  .react-tel-input .form-control {
    width: 100% !important;
  }
}
