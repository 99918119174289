.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f9f9f9;
}

.heading {
  font: normal 24px/24px "Regular", sans-serif !important;
  color: #333;
  margin-bottom: 20px;
}

.message {
  font: normal 18px/24px "Regular", sans-serif !important;
  font-size: 1.2rem;
  color: #4caf50;
  margin-top: 10px;
}

.loading {
  font: normal 24px/24px "Regular", sans-serif !important;
  color: #ff9800;
}

.error {
  font: normal 24px/24px "Regular", sans-serif !important;
  color: #f44336;
  margin-top: 20px;
  border: 1px solid #f44336;
  padding: 10px;
  border-radius: 5px;
  background-color: #ffe5e5;
}
