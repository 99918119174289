.mainContainerStyle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Prevents items from being centered and cut off */
  align-items: center;
  margin: auto;
  gap: 32px;
  padding: 50px 24px;
  min-height: 100vh; /* Ensures content isn't cut off */
}

.subContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.topBlockStyle {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: start;
}

.titleAndArrowViewStyle {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.arrowImgStyle {
  cursor: pointer;
}

.titleStyle {
  font: normal 500 24px/24px "Medium", sans-serif;
}

.subTitleStyle {
  font: normal 400 18px/24px "Regular", sans-serif;
}

.middleBlockStyle,
.userDetailsBlockStyle,
.venueDetailsBlockStyle,
.bannerAndInvitationStyle,
.galleryAndVideoStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.MuiFormHelperTextRootError {
  color: var(--red) !important;
  font: normal 400 14px/24px "Regular", sans-serif !important;
  margin-top: -22px !important;
  margin-bottom: 0px !important;
}

.bannerAndInvitationStyle,
.galleryAndVideoStyle {
  /* max-height: 500px;
  height: 100%;
  overflow-y: scroll; */
}

.inputStyle {
  max-width: 100%;
  width: 400px;
}

.createEventBtnStyle {
  width: 100%;
  font: normal 16px/24px "Regular", sans-serif !important;
}
.uploadMainBlockStytle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.uplaodBlockStyle,
.dragZoneBlockStyle {
  /* width: 400px; */

  width: 800px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--black);
  border-radius: 6px;
  padding: 24px;
}

.uploadLabelStyle {
  font: normal 18px/24px "Medium", sans-serif;
}
.dragZoneBlockStyle {
  border: 2px dashed var(--blue);
}

.fileUploadInfoStyle,
.erroMessageStyle {
  font: normal 400 14px/24px "Regular", sans-serif;
}
.uploadImgStyle {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.erroMessageStyle {
  color: var(--red);
}

.orTextStyle {
  font: normal 700 16px/24px "SemiBold", sans-serif;
}

.uploadFileLableStyle {
  border-radius: 4px;
  padding: 10px;
  background-color: rgb(2, 2, 21);
  color: var(--white);
}
.previewImgMainBlockStyle,
.previewContainerStyle {
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid var(--blue);
  padding: 24px;
  border-radius: 4px;
}
.previewContainerStyle {
  overflow-x: scroll;
  justify-content: space-evenly;
}

.previewImgBlockStyle {
  width: 80px;
  height: 80px;
  position: relative;
}
.previewVideoBlockStyle {
  width: 100%;
  height: 100%;
}

.previewImageStyle {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.deleteBtnStyle {
  position: absolute;
  padding: 0px 3px;
  background-color: var(--white);
  color: var(--red);
  border: none;
  border-radius: 50%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.templateBlockStyle {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.templateStyle {
  display: flex;
  width: 250px;
  max-width: 100%;
  height: 200px;
  max-height: 100%;
  border: 1px solid red;
  background-color: var(--white);
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Container styles */
.noCreditsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
  padding: 20px;
  background-color: #f8f9fa; /* Light background */
}

/* Heading styles */
.noCreditsHeading {
  font-size: 2rem;
  color: #333;
  margin-bottom: 10px;
}

/* Paragraph styles */
.noCreditsText {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 20px;
}

/* Button styles */
.purchaseButton {
  font-size: 1rem;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.purchaseButton:hover {
  background-color: #0056b3;
}

/* Responsive styles for tablets */
@media (max-width: 768px) {
  .noCreditsHeading {
    font-size: 1.5rem;
  }

  .noCreditsText {
    font-size: 1rem;
  }

  .purchaseButton {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

/* Responsive styles for mobile */
@media (max-width: 480px) {
  .noCreditsHeading {
    font-size: 1.2rem;
  }

  .noCreditsText {
    font-size: 0.9rem;
  }

  .purchaseButton {
    font-size: 0.8rem;
    padding: 6px 12px;
  }
}

/* Media Query Start */
@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
  .middleBlockStyle,
  .userDetailsBlockStyle,
  .venueDetailsBlockStyle,
  .bannerAndInvitationStyle,
  .galleryAndVideoStyle {
    gap: 24px;
  }
  .uplaodBlockStyle,
  .dragZoneBlockStyle,
  .previewImgMainBlockStyle {
    max-width: 400px;
  }
  .bannerAndInvitationStyle,
  .galleryAndVideoStyle {
    /* max-height: 300px;
    height: 100%;
    overflow-y: scroll; */
  }
}

.container {
  width: 800px;
  padding: 20px;
  background-color: #f7f8fc;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.heading {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.radioGroup {
  display: flex;
  justify-content: space-evenly;
}

.radioLabel {
  font-size: 16px;
  color: #444;
  cursor: pointer;
  display: flex;
  gap: 6px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between input fields */
  margin-top: 10px;
  width: 100%;
}
.inputContainerBlur {
  background: white;
  background: rgba(255, 255, 255, 0.8);
  filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  -moz-filter: blur(4px);
  -webkit-filter: blur(4px);
  pointer-events: none; /* Prevent interaction with the element */
  user-select: none;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.inputField {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.05);
  width: 100%;
}

.inputField:focus {
  border-color: #4a90e2;
  outline: none;
  box-shadow: 0px 0px 5px rgba(74, 144, 226, 0.5);
}

@media (max-width: 768px) {
  .radioGroup {
    flex-direction: column;
    gap: 10px;
  }
}

@media screen and (max-width: 530px) {
  .titleStyle {
    font: normal 500 20px / 24px "Medium", sans-serif;
  }

  .subTitleStyle {
    font: normal 400 14px / 24px "Regular", sans-serif;
  }

  .inputStyle,
  .subContainerStyle,
  .uplaodBlockStyle,
  .dragZoneBlockStyle {
    width: 100%;
  }
}

@media screen and (max-width: 430px) {
  .uplaodBlockStyle,
  .dragZoneBlockStyle {
    /* justify-content: center; */
    /* align-items: center; */
  }
  .previewContainerStyle {
    gap: 12px;
  }
}
/* Media Query End */
.mainContainerForPage {
  opacity: 0;
  animation: fadeIn 5s forwards;
}
@keyframes fadeIn {
  10% {
    opacity: 0.1;
  }
  20% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.6;
  }
  80% {
    opacity: 0.7;
  }
  90% {
    opacity: 0.8;
  }
  95% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

.loaderContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  animation: fadeInOut 4s forwards;
}
@keyframes fadeInOut {
  10% {
    opacity: 1;
  }
  20% {
    opacity: 0.9;
  }
  30% {
    opacity: 0.8;
  }
  40% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.6;
  }
  60% {
    opacity: 0.5;
  }
  80% {
    opacity: 0.4;
  }
  90% {
    opacity: 0.3;
  }
  95% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 100px;
  aspect-ratio: 1;
  background: linear-gradient(45deg, #60b99a 50%, #0000 0),
    linear-gradient(45deg, #0000 50%, #60b99a 0),
    linear-gradient(-45deg, #f77825 50%, #0000 0),
    linear-gradient(-45deg, #0000 50%, #f77825 0), linear-gradient(#554236 0 0);
  background-size: 50% 50%;
  background-repeat: no-repeat;
  animation: l18 1.5s infinite;
}
@keyframes l18 {
  0% {
    background-position: 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%;
  }
  25% {
    background-position: 0 100%, 100% 0, 50% 50%, 50% 50%, 50% 50%;
  }
  50% {
    background-position: 0 100%, 100% 0, 100% 100%, 0 0, 50% 50%;
  }
  75% {
    background-position: 50% 50%, 50% 50%, 100% 100%, 0 0, 50% 50%;
  }
  100% {
    background-position: 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%;
  }
}

.copedSuccess {
  font-size: 16px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  color: #ffffff;
  background-color: #28a745; /* Green to indicate success */
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;
  display: inline-block; /* Ensures it fits the text size */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeInOut 3s ease-in-out;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

