.edit-event-title-style {
  font: normal 24px/24px "Regular", sans-serif !important;
}
.edit-btn-action-style {
  font: normal 16px/24px "Medium", sans-serif !important;
}
.template-global {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.link-input-and-download-btn {
  flex-direction: column;
}

.download-button {
  align-items: center;
  background-color: #0a66c2;
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto,
    "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell,
    "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Lucida Grande", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  max-width: 480px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
}

.download-button:hover,
.download-button:focus {
  background-color: #16437e;
  color: #ffffff;
}

.download-button:active {
  background: #09223b;
  color: rgb(255, 255, 255, 0.7);
}

.download-button:disabled {
  cursor: not-allowed;
  background: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.3);
}

.download-button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.download-button:active {
  transform: scale(0.95);
}
.expiry-warning-text {
  color: red;
  margin: 8px 0 0 0;
  text-align: center;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  line-height: 1.5;
}

.location-icon {
  font-size: 16px;
  margin-right: 5px;
}
.para-styling {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}
.btn-styling {
  font-family: "monospace";
  display: flex;
  align-items: center;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
  cursor: pointer;
}
.edit-data-input-style {
  font: normal 16px/24px "Medium", sans-serif !important;
}
.hr-styling {
  width: 2px;
  height: 70px;
  background-color: #873e23;
  margin: 0px 5px;
}
/* .invitation-frame {
  min-width: 190px;
  font-size: 10px;
   background-image: url("../../assets/images/image_1_fmclnk.jpg");

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 30px;
  box-sizing: border-box;
  color: #873e23;
} */

.box-border-styling-80 {
  width: 90vw;
  margin: auto;
}
.box-border-styling-100 {
  width: 100%;
}
.box-border-styling {
  width: 100%;
  padding: 20px;
  margin: 20px 0px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: "Arial, sans-serif";
  background-color: #f9f9f9;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.btns-flex {
  margin: 10px auto;
  display: flex;
  gap: 30px;
}
.btnsflexbtns {
  width: 300px;
}
.stream-container {
  padding: 20px;
  background-color: #f7f8fc;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.heading {
  margin-bottom: 10px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.radio-group {
  display: flex;
  justify-content: space-evenly;
}

.radio-label {
  font-size: 16px;
  color: #444;
  cursor: pointer;
  display: flex;
  gap: 6px;
}

.input-container {
  display: flex;
  flex-direction: row;
  gap: 10px; /* Space between input fields */
  margin-top: 10px;
}
.input-container-blur {
  background: white;
  background: rgba(255, 255, 255, 0.8);
  filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  -moz-filter: blur(4px);
  -webkit-filter: blur(4px);
  pointer-events: none; /* Prevent interaction with the element */
  user-select: none;
}

.input-group {
  margin: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.input-field {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.05);
  width: 100%;
}

.videoContainer {
  width: 80%;
  margin: 0 auto;
  /* background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 50px 30px;
  box-sizing: border-box; */
}
.video-container {
  /* width: 400px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 30px;
  box-sizing: border-box;
  margin: auto; */
}
.video-container-live {
  /* width: 400px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 30px;
  box-sizing: border-box;
  min-height: 250px;
  margin: auto;
  min-height: 330px; */
}
/*
.video-container2 {
  margin: auto;
   display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 50px 40px;
  background-image: url("../../../public/invitation_bg_frame.jpg"); 
  overflow: hidden;
  width: 400px;
  box-sizing: border-box;
  min-height: 330px; 
}
 
.video-container2 video {
  width: inherit;
   border-radius: 20px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-sizing: border-box; 
} */

.input-field:focus {
  border-color: #4a90e2;
  outline: none;
  box-shadow: 0px 0px 5px rgba(74, 144, 226, 0.5);
}

@media (min-width: 900px) {
  .input-field {
    width: 450px;
  }
}
.hei-width-for-preview-live {
  width: 50% !important;
}
.height-width-for-edit-live {
  max-width: 80% !important;
  max-height: 350px !important;
}

.hls-wrapper {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 10px;
  /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    height: 100%; */
}
.hls-player-block {
  position: relative;
}
.hls-player-styles {
  width: 100% !important;
  height: 60% !important;
}
.error-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.4rem;
}

.youtube_recorded_block,
.youtube_live_block {
  width: 100%;
}
.edit-modal-input {
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  padding: 10px 20px;
}

.youtube_recorded {
  width: 100%;
  height: 315px;
  max-width: 100%;
  border-radius: 8px;
}
/* Edit Section - Scrollable */
.edit-section {
  width: 50%;
  box-sizing: border-box;
  height: 100vh; /* Full viewport height */
  overflow-y: auto; /* Scrollable content */
  padding-right: 8px;
}

/* Preview Section - Fixed Scrollable */
.preview-section {
  width: 47.5%;
  box-sizing: border-box;
  height: 100vh; /* Full viewport height */
  position: sticky; /* Fixed at the top */
  top: 0; /* Sticks to the top of the viewport */
  overflow-y: auto; /* Scrollable content */
  background-color: #ffffff;
  border-left: 1px solid #ddd;
}

/* Custom Scrollbars */
.edit-section::-webkit-scrollbar,
.preview-section::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.edit-section::-webkit-scrollbar-thumb,
.preview-section::-webkit-scrollbar-thumb {
  background-color: #b0b0b0; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners */
}

.edit-section::-webkit-scrollbar-track,
.preview-section::-webkit-scrollbar-track {
  background-color: #f9f9f9; /* Scrollbar track color */
}

/* Responsive Behavior */
.slider-btn {
  display: none;
}

@media (max-width: 800px) {
  .edit-section {
    width: 100%;
  }
  .preview-section {
    display: none;
  }
  .slider-btn {
    display: block;
  }
}

@media (max-width: 800px) {
  .edit-section {
    width: 100%;
  }
  .preview-section {
    display: none;
  }
  .slider-btn {
    display: block;
  }
}

@media (max-width: 800px) {
  .edit-modal-input {
    width: 100%;
  }
}
.live-url-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.live-url-input:focus {
  border-color: #007bff;
  outline: none;
}

/* .video-js-edit {
  width: 95% !important;
}
@media (max-width: 430px) {
  .video-js-edit {
    height: 80px !important;
  }
} */

@media (max-width: 500px) {
  .btnsflexbtns {
    width: 251px;
  }
}
@media (max-width: 768px) {
  .radioGroup {
    flex-direction: column;
    gap: 10px;
  }
}
/*wedding card */
.invitation-frame {
  width: 400px;
  /* background-image: url("../../assets/images/image_1_fmclnk.jpg"); */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
  min-height: 330px;
}
.time-date {
  margin: 5px auto;
  width: 200px;
}
@media (max-width: 300px) {
  /* .invitation-frame {
    width: 350px;
    height: 250px;
    padding: 30px;
  } */
  .time-date {
    width: 100px;
  }
}
@media only screen and (min-width: 300px) {
  /* .invitation-frame {
    width: 350px;
    height: 250px;
    padding: 30px;
  } */
  .time-date {
    width: 130px;
  }
}
/* @media only screen and (min-width: 400px) {
  .invitation-frame {
    width: 350px;
    height: 250px;
    padding: 30px;
  }
} */

@media only screen and (min-width: 540px) {
  /* .invitation-frame {
    width: 350px;
    height: 250px;
    padding: 30px 50px;
  } */
  .time-date {
    width: 160px;
  }
}
/* @media only screen and (min-width: 640px) {
  .invitation-frame {
    width: 350px;
    height: 250px;
    padding: 30px 50px;
  }
}
@media only screen and (min-width: 768px) {
  .invitation-frame {
    width: 350px;
    height: 250px;
    padding: 40px;
  }
}
@media only screen and (min-width: 820px) {
  .invitation-frame {
    width: 350px;
    height: 250px;
    padding: 40px;
  }
}
@media only screen and (min-width: 1024px) {
  .invitation-frame {
    width: 650px;
    height: 250px;
    padding: 128px;
  }
} */

.invitation-content {
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.invitation-header {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.invitation-caption {
  font-weight: bold;
  margin-top: 5px;
}

.divider {
  border-left: 2px solid #ccc; /* Vertical divider */
  height: 100%;
  margin: 0 10px;
}

.venue-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px; /* Adjust as needed */
  overflow: hidden; /* or auto */
  max-width: 100%;
}

.venue-title {
  text-decoration: underline;
  font-style: italic;
  margin-bottom: 5px;
}

.venue-address {
  display: flex;
  align-items: center;
  word-wrap: break-word; /* Allow long words to break */
  overflow-wrap: break-word;
}

.location-icon {
  margin-right: 3px;
}

.event-date {
  border-top: 2px solid #873e23;
  border-bottom: 2px solid #873e23;
  padding: 0px 5px;
}

.invitation-footer {
  text-align: center;
  margin-top: 20px;
  font-size: 10px;
}

/* Responsive Styles */
@media (max-width: 800px) {
  .invitation-content {
    width: 90%;
  }
}

.sidebar {
  z-index: 1;
  min-width: 330px;
  margin-top: 60px;
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 90%;
  transition: transform 0.3s ease;
  transform: translateX(100%);
  background-color: white;
}

.sidebar.open {
  transform: translateX(0);
}

.toggle-button {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  position: absolute;
  width: 40px;
  top: 0px;
  left: -40px; /* Adjust position */
  cursor: pointer;
  height: 100px;
  background-color: #444;
  color: white;
  border: none;
  padding: 10px 20px;
  transition: background-color 0.3s;
  outline: none;
}

.toggle-text {
  display: inline-block;
  transform: rotate(-90deg); /* Rotate text */
  transform-origin: left bottom; /* Set the rotation origin */
  margin-left: 8px;
  margin-top: 30px;
}

.toggle-button:hover {
  background-color: #555;
}

.sidebar-content {
  height: calc(100% - 40px); /* Adjust height considering button */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Enable vertical scrolling */
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  margin: 15px 0;
}

.sidebar a {
  color: white;
  text-decoration: none;
}

.scrollingmess {
  width: 400px; /* Ensure it takes 100% of the parent's width */
  margin: auto; /* Centers the child element */
  background-color: #f2f2f2; /* Light grey background */
  padding: 10px 0; /* Padding for vertical spacing */
  overflow: hidden; /* Prevent any overflow */
  white-space: nowrap; /* Prevent text from wrapping */
  box-sizing: border-box;
}

.mainContainerForPage {
  opacity: 0;
  animation: fadeIn 5s forwards;
}
@keyframes fadeIn {
  10% {
    opacity: 0.1;
  }
  20% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.6;
  }
  80% {
    opacity: 0.7;
  }
  90% {
    opacity: 0.8;
  }
  95% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

.editingInvitationHeading {
  font-size: 30px;
  margin-top: 20px;
}

.loaderContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  animation: fadeInOut 4s forwards;
}
@keyframes fadeInOut {
  10% {
    opacity: 1;
  }
  20% {
    opacity: 0.9;
  }
  30% {
    opacity: 0.8;
  }
  40% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.6;
  }
  60% {
    opacity: 0.5;
  }
  80% {
    opacity: 0.4;
  }
  90% {
    opacity: 0.3;
  }
  95% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 100px;
  aspect-ratio: 1;
  background: linear-gradient(45deg, #60b99a 50%, #0000 0),
    linear-gradient(45deg, #0000 50%, #60b99a 0),
    linear-gradient(-45deg, #f77825 50%, #0000 0),
    linear-gradient(-45deg, #0000 50%, #f77825 0), linear-gradient(#554236 0 0);
  background-size: 50% 50%;
  background-repeat: no-repeat;
  animation: l18 1.5s infinite;
}
@keyframes l18 {
  0% {
    background-position: 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%;
  }
  25% {
    background-position: 0 100%, 100% 0, 50% 50%, 50% 50%, 50% 50%;
  }
  50% {
    background-position: 0 100%, 100% 0, 100% 100%, 0 0, 50% 50%;
  }
  75% {
    background-position: 50% 50%, 50% 50%, 100% 100%, 0 0, 50% 50%;
  }
  100% {
    background-position: 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%;
  }
}

.changesOfList {
  margin-top: 10px;
  display: inline;
}
.settings-semi-container {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: "4px";
}
.subcontainers {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-bottom: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.subcontainers select {
  width: 100%;
  padding: 6px 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.subcontainers select:focus {
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.4);
  outline: none;
}

.subcontainers label {
  font-size: 14px;
  color: #333;
}

.subcontainers input {
  width: 100%;
  padding: 6px 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.subcontainers input {
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.4);
  outline: none;
}

.w-50 {
  width: 50%;
}
.input-option:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}
.timings {
  font-size: medium;
  display: flex;
  margin: 5px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-direction: column;
}

.timings p {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  font-size: larger;
  justify-content: center;
  text-align: center;
}

.timings span {
  display: inline-block;
}

.bannerz {
  display: flex;
  flex-direction: column;
}

.bannerz h1 {
  font-size: 2rem;
  text-align: center;
  margin: 20px 0;
}

.bannerz img {
  max-width: 90%;
  max-height: 500px;
  margin: 0 auto;
  cursor: pointer; /* Indicate the image is clickable */
}

.selected-not-selected {
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
}

.not-selected {
  background-color: #bbccde;
  color: white;
}
.selected {
  background-color: #007bff;
  color: white;
}
.selected-not-selected:hover {
  background-color: #007bff;
  transform: scale(1.05);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}
.template-button-para {
  font-size: 12px;
  font-family: Arial, sans-serif;
  color: #333;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  transition: all 0.3s ease;
}

.anim-bg-gradient {
  background: linear-gradient(120deg, darkmagenta, crimson, orange);
  background-size: 200% 100%;
  background-position: 100% 0;
  transition: background-position 0.5s;
}

.anim-bg-gradient:hover {
  background-position: 0 0;
}

/* 
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;

  background: #4caf50; 
  cursor: pointer;
  transition: background-color 0.3s ease; 
}

input[type="range"]:hover::-webkit-slider-thumb {
  background-color: #45a049;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;

  background: #ddd; 
  border-radius: 5px;
}

input[type="range"]::-moz-range-thumb {
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  background: #ddd;
}

input[type="range"]::-ms-thumb {
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 8px;
  background: #ddd;
  border: none;
} */
.ads-enable-container {
  display: flex;
  align-items: center;
  gap: 5px;
}
@media (max-width: 450px) {
  .ads-enable-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }
}
.generate-link-loader {
  width: 24px;
  height: 24px;
  border: 5px solid #fff;
  border-bottom-color: #ff3d00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.edit-coped-success {
  font-size: 16px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  color: #ffffff;
  background-color: #28a745; /* Green to indicate success */
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;
  display: inline-block; /* Ensures it fits the text size */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeInOut 3s ease-in-out;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}
