.p-1 {
  padding: 20px;
}
.p-md-3 {
  padding: 1rem;
}
.border {
  border: 1px solid #dee2e6 !important; /* Default border color */
}
.border-primary {
  border-color: #007bff !important; /* Bootstrap primary color */
}
.border-primary {
  border-color: #007bff !important; /* Bootstrap primary color */
}
.rounded {
  border-radius: 0.25rem !important;
}
.mx-3 {
  margin-left: 1rem !important; /* Left margin */
  margin-right: 1rem !important; /* Right margin */
}
.text-center {
  text-align: center !important;
}
.m-0 {
  margin: 0;
}
.h2 {
  font-size: 1.5rem; /* Bootstrap default size for h2 */
  font-weight: 500; /* Default weight for h2 */
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px; /* Default negative margin to align columns */
  margin-left: -15px;
}
.list-unstyled {
  padding: 20px;
  list-style: none; /* Removes bullet points */
}
/* Mobile styles: Full width */
.col-12 {
  flex: 0 0 90%; /* Full width */
}

/* Small screens (≥576px): Two columns */
@media (min-width: 576px) {
  .col-sm-6 {
    flex: 0 0 50%; /* 50% width */
  }
}

/* Large screens (≥992px): Three columns */
@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 33.33%; /* 33.33% width */
  }
  .card-img-top {
    height: 300px;
  }
}
@media (max-width: 992px) {
  .card-img-top {
    height: 200px;
    width: 100%;
  }
}

.p-2 {
  padding: 0.5rem !important;
}
.flex-column {
  flex-direction: column !important;
}
.text-secondary {
  color: #6c757d !important; /* Default Bootstrap secondary color */
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}

@media (min-width: 768px) {
  .p-md-3 {
    padding: 1rem !important;
  }
}
.border {
  border: 1px solid #dee2e6 !important; /* Default border color */
}
.border-primary {
  border-color: #007bff !important; /* Default Bootstrap primary color */
}
.rounded {
  border-radius: 0.25rem !important;
}
.mx-3 {
  margin-left: 1rem !important; /* 16px */
  margin-right: 1rem !important; /* 16px */
}
.text-center {
  text-align: center !important;
}
.m-0 {
  margin: 0;
}
.h2 {
  font-size: 1.5rem; /* Typically corresponds to h2 */
  font-weight: 500; /* Medium weight */
}
.fw-bold {
  font-weight: bold !important;
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
}
.align-items-center {
  align-items: center !important;
}
.justify-content-center {
  justify-content: center !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.rounded {
  border-radius: 0.25rem !important; /* 4px */
}
.rounded-circle {
  border-radius: 50% !important;
}
@media (min-width: 768px) {
  .p-md-3 {
    padding: 1rem !important;
  }
}
.border {
  border: 1px solid #dee2e6 !important; /* Default Bootstrap border color */
}
.border-primary {
  border-color: #007bff !important; /* Default Bootstrap primary color */
}
.rounded {
  border-radius: 0.25rem !important;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.bg-primary {
  background-color: #007bff !important; /* Default Bootstrap primary color */
}
.rounded {
  border-radius: 0.25rem !important; /* 4px */
}
@media (min-width: 768px) {
  .p-md-5 {
    padding: 3rem !important;
  }
}
.p-3 {
  padding: 1rem !important;
}
.justify-content-start {
  justify-content: flex-start !important; /* Aligns items to the start of the container */
}
.align-items-start {
  align-items: flex-start !important; /* Aligns items to the start of the container's cross axis */
}
.dashed-circle {
  border: 2px dashed #007bff; /* Example color */
}
.rounded-circle {
  border-radius: 50% !important;
}
.me-3 {
  margin-right: 1rem !important; /* 16px */
}
/* Default text color */
.text-white {
  color: white; /* Default color */
}

.row {
  display: flex;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.mt-2 {
  margin-top: 0.5rem;
}
.text-danger {
  color: #dc3545;
}

.mb-0 {
  margin-bottom: 0;
}

.fs-4 {
  font-size: 1.5rem;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

/* Primary Button Color */
.btn-primary {
  color: #fff; /* White text */
  background-color: #007bff; /* Bootstrap's primary color */
  border: 1px solid transparent; /* Default border */
}

.btn-primary:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

/* Padding */
.p-3 {
  padding: 25px;
}

/* Form Control */
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem; /* Default padding */
  font-size: 1rem; /* Default font size */
  border: 1px solid #ced4da; /* Default border */
  border-radius: 0.25rem; /* Rounded corners */
}

/* Justify Content Center */
.justify-content-center {
  justify-content: center; /* Center items horizontally */
}

/* Align Items Center */
.align-items-center {
  align-items: center; /* Center items vertically */
}
/* Margin Top */
.mt-4 {
  margin-top: 1.5rem; /* Equivalent to Bootstrap's mt-4 */
}

/* Padding Top */
.pt-4 {
  padding-top: 1.5rem; /* Equivalent to Bootstrap's pt-4 */
}

/* Text Center */
.text-center {
  text-align: center; /* Center align text */
}

/* Padding Bottom */
.pb-3 {
  padding-bottom: 1rem; /* Equivalent to Bootstrap's pb-3 */
}
/* Light Text Color */
.text-light {
  color: #f8f9fa; /* Bootstrap's light text color */
}
/* Margin Top */
.mt-4 {
  margin-top: 1.5rem; /* Equivalent to Bootstrap's mt-4 */
}
/* Margin End for Small Screens */
.me-1 {
  margin-right: 0.25rem; /* Equivalent to Bootstrap's me-1 */
}

/* Margin End for Medium Screens and Up */
@media (min-width: 768px) {
  .me-md-4 {
    margin-right: 1.5rem; /* Equivalent to Bootstrap's me-md-4 */
  }
}
/* Margin Top */
.mt-3 {
  margin-top: 30px;
}
.row {
  display: flex;
  justify-content: space-around;
  gap: 10px;
}
.col {
  width: 47%;
}
@media (max-width: 768px) {
  .row {
    padding-bottom: 0px;
  }
  .col {
    width: 100%;
  }
}

body {
  font-family: "Arial", sans-serif;
  overflow-x: hidden;
}

.bg-banner {
  background-image: url("../../assets/images/pexels-vlada-karpovich-7100322_1_jwd1cc.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #fff;
}

.banner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}

.section-head {
  margin: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #384bff;
  font-family: "Plus Jakarta Sans", sans-serif;
  text-transform: uppercase;
  font-size: 1.5rem; /* Adjusted font size */
}

.service-card {
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px; /* Added border radius */
  overflow: hidden; /* Ensure card doesn't overflow */
}

.service-card:hover {
  transform: translateY(-1px);
}

.card {
  overflow: hidden;
  width: 90%;
}

.card-img-top {
  object-fit: cover; /* Maintain aspect ratio */
  transition: transform 0.3s ease;
}

.card-img-top:hover {
  transform: scale(1.05);
}

.card-title {
  font-weight: bold;
  text-align: center;
  margin: 10px 0;
}

.card-text {
  color: #555;
}

.img-responsive {
  max-height: 250px;
}

.dashed-circle {
  color: #fff;
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
}

.dashed-circle::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px dashed;
  border-radius: 50%;
  animation: rotate 10s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dashed-circle .child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

hr {
  color: #fff;
  width: 100%;
  margin: 20px 0;
  font-size: 1.5rem;
  height: 2px;
}
.contact-link {
  font-size: 1rem;
  /* text-wrap: ; */
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s;
}

.contact-link:hover {
  text-decoration: underline;
  color: #2a2d02;
}

.serif {
  font-family: "Times New Roman", serif;
}
@media (min-width: 768px) and (max-width: 992px) {
  .contact-link {
    font-size: 12px;
  }
}

.width {
  width: 100%;
}
.footer {
  background-color: #18185e;
  padding: 20px;
}

.icon {
  border: 1px #fff solid;
  padding: 8px 15px;
  color: #fff;
  font-size: 30px;
}
.icon:hover {
  border: none;
  background-color: #384bff;
}
.img-fluid {
  max-width: 250px;
  height: auto;
}
@media (max-width: 768px) {
  .img-fluid {
    height: 150px;
    margin-bottom: 10px;
  }
  .who-are-we-container .who-are-we-container-1 {
    font-size: 20px;
  }
}
.who-are-we-container .who-are-we-container-1 {
  width: 70%;
}
.label {
  color: #6c757d;
}

@media (max-width: 1000px) {
  .bg-banner {
    height: 450px;
  }
}
.section-font-heading {
  margin-top: 2px;
}
@media (min-width: 768px) {
  .who-are-we-container {
    order: 0;
    margin-right: 20px;
  }
  .who-are-we-container-1 {
    margin-left: 20px;
  }
  .img-responsive {
    order: 1;
  }
  .section-head {
    font-size: 1.25rem;
  }
}

@media (max-width: 768px) {
  .bg-banner {
    height: 300px;
  }
}

@media (max-width: 500px) {
  .who-are-we-container .who-are-we-container-1 {
    width: 100%;
  }
  .section-head {
    margin: 20px 0px;
    padding: 5px;
    margin-bottom: 10px;
  }

  .label {
    color: #6c757d;
    font-size: 10px;
    margin-bottom: 1px !important;
  }
  .form-control {
    padding: 5px;
    font-size: 10px;
    margin-bottom: 0px !important;
  }
  .bg-banner {
    height: 240px;
  }
  .section-font-heading {
    font-size: 14px;
  }
  .section-font {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .section-matter {
    font-size: 12px;
  }
  .dashed-circle::before {
    display: none;
    margin: 0;
  }
  .dashed-circle {
    width: 50px;
    height: 50px;
  }
  .icon {
    border: none;
  }
}
/* General container */
.page-container {
  position: relative;
  min-height: 100vh;
  background: linear-gradient(to bottom right, #eef2ff, #ffffff, #f3e8ff);
  padding-top: 4rem; /* pt-16 */
}

/* Centering the content */
.page-content {
  max-width: 112rem;
  margin: 0 auto;
  padding: 1rem 1rem 4rem;
  text-align: center;
}

/* Heading Styles */
.page-heading {
  font-size: 3rem; /* text-5xl */
  font-weight: bold;
  color: #1f2937; /* text-gray-900 */
  margin-bottom: 2rem; /* mb-8 */
}

.page-heading span {
  color: #4c51bf; /* text-indigo-600 */
}

.page-heading.animate {
  opacity: 1;
  transform: translateY(0);
}

.page-heading.hidden {
  opacity: 0;
  transform: translateY(20px);
}

/* Paragraph Styles */
.page-paragraph {
  font-size: 1.25rem; /* text-xl */
  color: #4a5568; /* text-gray-600 */
  margin-bottom: 3rem; /* mb-12 */
  max-width: 48rem; /* max-w-3xl */
  margin-left: auto;
  margin-right: auto;
}

/* Button styles */
.page-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.page-buttons button {
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  font-size: 1.125rem; /* text-lg */
  font-weight: 600;
  transition: all 0.3s ease;
}

.page-buttons .get-started {
  background-color: #4c51bf; /* bg-indigo-600 */
  color: white;
  border: none;
}

.page-buttons .get-started:hover {
  background-color: #434190; /* hover:bg-indigo-700 */
}

.page-buttons .white-label-solutions {
  background-color: lightgray; /* bg-indigo-600 */
  color: white;
  border: none;
}

.page-buttons .white-label-solutions:hover {
  background-color: gray; /* hover:bg-indigo-700 */
}

.page-buttons .watch-demo {
  background-color: transparent;
  border: 2px solid #4c51bf; /* border-indigo-600 */
  color: #4c51bf; /* text-indigo-600 */
}

.page-buttons .watch-demo:hover {
  background-color: #f3f4f6; /* hover:bg-indigo-50 */
}

/* Grid layout for features */
.feature-grid {
  margin-top: 5rem; /* mt-20 */
  display: grid;
  grid-template-columns: 1fr; /* For mobile by default */
  gap: 2rem;
  max-width: 64rem; /* max-w-4xl */
  margin-left: auto;
  margin-right: auto;
}

.feature-grid .feature-item {
  text-align: center;
}

.feature-grid .feature-icon {
  width: 2rem;
  height: 2rem;
  color: #4c51bf; /* text-indigo-600 */
}

.feature-grid .feature-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #1f2937; /* text-gray-900 */
  margin-top: 1rem;
}

.feature-grid .feature-description {
  color: #4a5568; /* text-gray-600 */
  margin-top: 0.5rem;
}

/* Mobile responsiveness */
@media (min-width: 640px) {
  /* sm */
  .page-buttons {
    flex-direction: row;
  }

  .feature-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 768px) {
  /* md */
  .page-heading {
    font-size: 4rem; /* md:text-6xl */
  }
}

/* Background decorative elements */
.background-decor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.background-decor .blob-one {
  position: absolute;
  top: -10rem;
  right: -10rem;
  width: 20rem;
  height: 20rem;
  background-color: #d6bcf7; /* bg-purple-300 */
  border-radius: 50%;
  opacity: 0.7;
  animation: blob-animate 4s infinite;
}

.background-decor .blob-two {
  position: absolute;
  bottom: -10rem;
  left: -10rem;
  width: 20rem;
  height: 20rem;
  background-color: #a3bffa; /* bg-indigo-300 */
  border-radius: 50%;
  opacity: 0.7;
  animation: blob-animate 4s infinite 2s;
}

/* Keyframes for blob animation */
@keyframes blob-animate {
  0% {
    transform: scale(1) translateX(0) translateY(0);
  }
  50% {
    transform: scale(1.2) translateX(40px) translateY(40px);
  }
  100% {
    transform: scale(1) translateX(0) translateY(0);
  }
}

.feature-item {
  background-color: white;
  border-radius: 10px;
  padding: 20px 25px;
}

.feature-icon {
  margin: auto;
}
/* General Section */
.features-section {
  padding-top: 5rem; /* py-20 */
  padding-bottom: 5rem; /* py-20 */
  background-color: #ffffff; /* bg-white */
}

/* Container */
.features-container {
  max-width: 112rem; /* max-w-7xl */
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem; /* px-4 */
  padding-right: 1rem; /* px-4 */
}

@media (min-width: 640px) {
  .features-container {
    padding-left: 1.5rem; /* sm:px-6 */
    padding-right: 1.5rem; /* sm:px-6 */
  }
}

@media (min-width: 1024px) {
  .features-container {
    padding-left: 2rem; /* lg:px-8 */
    padding-right: 2rem; /* lg:px-8 */
  }
}

/* Text Content Section */
.features-text {
  text-align: center; /* text-center */
  margin-bottom: 4rem; /* mb-16 */
}

.features-heading {
  font-size: 2.25rem; /* text-4xl */
  font-weight: bold;
  color: #1f2937; /* text-gray-900 */
  margin-bottom: 1rem; /* mb-4 */
}

.features-description {
  font-size: 1.25rem; /* text-xl */
  color: #4a5568; /* text-gray-600 */
  max-width: 40rem; /* max-w-2xl */
  margin-left: auto;
  margin-right: auto;
}

/* Feature Grid */
.features-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
}

@media (min-width: 768px) {
  .features-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .features-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Feature Card */
.feature-card {
  height: 160px;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  background: linear-gradient(to bottom right, #ffffff, #e0e7ff);
  border-radius: 1rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease,
    transform 0.3s ease;
}

.feature-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: linear-gradient(to bottom right, #f7fafc, #c3dafe);
  transform: scale(1.05);
}
@media (min-widh: 767px) {
  .feature-card {
    width: 100%;
  }
}

/* Feature Icon Section */
.feature-icon-wrapper {
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  background-color: #ebf4ff;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4c51bf;
}

.feature-icon {
  font-size: 1.5rem;
}

/* Feature Title */
.feature-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 0.7rem;
  font-family: "Montserrat", "Helvetica", sans-serif;
}

/* Feature Description */
.feature-description-text {
  color: #4a5568;
}

.custom-services-gradient-section {
  padding: 50px 20px;
  background: linear-gradient(to bottom right, #ebf4ff, #ffffff, #f3e8ff);
}
.custom-services-container {
  position: relative;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .custom-services-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .custom-services-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.prevButton,
.nextButton {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  background-color: white;
  color: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  z-index: 1;
  border-radius: 50%;
  border: 1px solid rgba(40, 16, 16, 0.05);
}

.prevButton {
  left: 5px;
}

.nextButton {
  right: 5px;
}
.prevButton:hover,
.nextButton:hover {
  background-color: #aeebf1;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.custom-services-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
}

@media (min-width: 480px) {
  .custom-services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .service-card h3 {
    font-size: 1rem !important;
  }
  .service-card p {
    font-size: 0.8rem !important;
  }
}

@media (min-width: 768px) {
  /* md breakpoint */
  .custom-services-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1024px) {
  /* lg breakpoint */
  .custom-services-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .service-card h3 {
    font-size: 1.125rem !important;
  }
  .service-card p {
    font-size: 1.125rem !important;
  }
}

.service-card {
  padding: 2rem;
  border-radius: 1rem;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease-in-out;
  position: relative;
}

.service-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #f3f4f6;
  border: 2px solid #4f46e5;
}

.service-card-popular {
  border: 2px solid #4f46e5;
}

.service-card img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  margin-bottom: 1.5rem;
}

.service-card h3 {
  font-size: 1.125rem;
  font-weight: 700;
  color: #1f2937;
  margin-bottom: 0.5rem;
  text-align: center;
  font-family: "Montserrat", "Helvetica", sans-serif;
}

.service-card p {
  font-size: 1.125rem;
  line-height: 1.6;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2rem;
  letter-spacing: 0.5px;
  padding: 0.5rem 0;
  border-radius: 0.5rem;
  transition: transform 0.3s ease, color 0.3s ease;
}

.service-card p:hover {
  transform: translateY(-5px);
}
.text-services-section-center {
  text-align: center;
  margin-bottom: 4rem;
}

.service-section-heading {
  font-size: 2.25rem;
  font-weight: 700;
  color: #1f2937;
  margin-bottom: 1rem;
}
.service-section-text {
  font-size: 1.25rem;
  color: #4a5568;
  max-width: 42rem;
  margin-left: auto;
  margin-right: auto;
}

.service-card-description {
  font-size: 1rem;
  line-height: 1.75;
  color: #4a5568;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  margin-bottom: 1.5rem;
  text-align: justify;
}

.typing-effect {
  font-size: 5rem;
  font-weight: 700;
  color: #4c51bf; /* Indigo color */
  font-family: "Roboto", sans-serif;
  display: inline-block;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Ensures the text is hidden initially */
  border-right: 4px solid rgba(255, 255, 255, 0.75); /* Cursor effect */
  animation: typing 2s ease-out forwards, blink 0.75s step-end infinite;
}

@media (max-width: 480px) {
  .typing-effect {
    margin-top: 1rem;
    font-size: 3rem;
  }
  .page-heading {
    font-size: 2.5rem;
  }
}
@media (max-width: 768px) {
  .typing-effect {
    margin-top: 1rem;
    font-size: 3.5rem;
  }
}

/* Typing animation */
@keyframes typing {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

/* Blinking cursor effect */
@keyframes blink {
  50% {
    border-color: transparent; /* Makes the cursor blink */
  }
}

/* HTML: <div class="loader"></div> */
.loaderTailSpin {
  width: 30px;
  aspect-ratio: 1;
  display: grid;
  animation: l14 4s infinite;
}
.loaderTailSpin::before,
.loaderTailSpin::after {
  content: "";
  grid-area: 1/1;
  border: 8px solid;
  border-radius: 50%;
  border-color: red red #0000 #0000;
  mix-blend-mode: darken;
  animation: l14 1s infinite linear;
}
.loaderTailSpin::after {
  border-color: #0000 #0000 blue blue;
  animation-direction: reverse;
}
@keyframes l14 {
  100% {
    transform: rotate(1turn);
  }
}

/* Feature Grid */
.label-features-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

/* .label-feature-card {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 2rem;
  background: linear-gradient(to bottom right, #f9caca, #e0e7ff);
  border-radius: 1rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease,
    transform 0.3s ease;
}
.label-feature-card {
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  background: linear-gradient(to bottom right, #f9caca, #e0e7ff);
  border-radius: 1rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease,
    transform 0.3s ease;
  position: relative; Position for the title and other elements 
} */

.label-feature-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: linear-gradient(to bottom right, #f4f5f6, #c3dafe);
  transform: scale(1.05);
}

.label-feature-card {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 2rem;
  background: linear-gradient(to bottom right, #f9caca, #e0e7ff);
  border-radius: 1rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease,
    transform 0.3s ease;
  position: relative; /* Position for the title and other elements */
}

.label-feature-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: linear-gradient(to bottom right, #f4f5f6, #c3dafe);
  transform: scale(1.05);
}

/* Hide the title and show the description on hover */
.label-feature-card:hover .label-feature-title {
  display: none;
}

.label-feature-card:hover .labelfeature-description-text {
  display: inline-block; /* Show the description when hovered */
}

.labelfeature-description-text {
  display: none; /* Hide the description by default */
  font-size: 14px;
  color: #000000cf;
  text-align: center;
}

.label-feature-title {
  text-transform: uppercase;
  font-family: Gt Walsheim Condensed Black, sans-serif;
  font-size: 3em;
  color: #a93636;
  display: inline;
}
@media (min-width: 1024px) {
  .label-feature-card {
    height: 200px;
    width: 30%;
  }
  .label-feature-title {
    font-size: 2em;
  }
}
@media (max-width: 1024px) {
  .label-feature-card {
    height: 200px;
    width: 30%;
  }
  .label-feature-title {
    font-size: 1em;
  }
}
@media (max-width: 768px) {
  .label-feature-card {
    width: 47.5%;
  }
  .label-feature-title {
    font-size: 1em;
  }
}
@media (max-width: 450px) {
  .label-feature-card {
    width: 100%;
  }
  .label-feature-title {
    font-size: 1em;
  }
}
.price-heading {
  font-weight: 800;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.pricing-card-container {
  max-width: 300px;
  min-width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  height: 350px;
  border: 1px solid #6b56e9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 10px;
  overflow: hidden;
  transition: background-image 0.4s ease, transform 0.4s ease;
  /* position: relative; */
  /* overflow: hidden; */
  border-radius: 20px;
}
.pricing-card-container:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
/* @keyframes bg {
  0% {
    background-size: 0 3px, 3px 0, 0 3px, 3px 0;
  }
  25% {
    background-size: 100% 3px, 3px 0, 0 3px, 3px 0;
  }
  50% {
    background-size: 100% 3px, 3px 100%, 0 3px, 3px 0;
  }
  75% {
    background-size: 100% 3px, 3px 100%, 100% 3px, 3px 0;
  }
  100% {
    background-size: 100% 3px, 3px 100%, 100% 3px, 3px 100%;
  }
}

.pricing-card-container:hover {
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #6b56e9 100%, #6b56e9 100%),
    linear-gradient(to bottom, #6b56e9 100%, #6b56e9 100%),
    linear-gradient(to right, #6b56e9 100%, #6b56e9 100%),
    linear-gradient(to bottom, #6b56e9 100%, #6b56e9 100%);
  background-size: 100% 3px, 3px 100%, 100% 3px, 3px 100%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  animation: bg 1.25s cubic-bezier(0.19, 1, 0.22, 1) 1;
  animation-play-state: paused;
  border-radius: 20px;
}

.pricing-card-container:hover {
  border-radius: 20px;
  animation-play-state: running;
} */

.num-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.button-started {
  align-items: center;
  background-image: linear-gradient(144deg, #c594e9, #6b56e9 50%, #84e4ea);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  font-family: Phantomsans, sans-serif;
  justify-content: center;
  line-height: 1em;
  width: 100%;
  padding: 12px 0px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.button-started:active,
.button-started:hover {
  outline: 0;
}

@media (max-width: 400px) {
  .button-started {
    font-size: 14px;
  }

  .pricing-card-container {
    margin: 30px 10px;
  }
}
.svg-wrapper {
  margin: 0 auto;
}

.shape {
  stroke-dasharray: 140 540;
  stroke-dashoffset: -474;
  stroke-width: 8px;
  fill: transparent;
  stroke: #19f6e8;
  border-bottom: 5px solid black;
  transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
}

.text {
  font-family: "Roboto Condensed";
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 8px;
  color: #fff;
  top: -48px;
  pointer-events: none;
}

.shape:hover {
  stroke-width: 2px;
  stroke-dashoffset: 0;
  stroke-dasharray: 760;
}

/* .container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.animated-word {
  font-family: Helvetica;
  letter-spacing: 0.1em;
  font-weight: 800;
  font-size: 16px;
  text-align: center;
  color: #202125;
  cursor: pointer;
  width: 100%;
  transition: all 600ms cubic-bezier(0.2, 0, 0, 0.8);
}

.animated-word:hover {
  color: #6b56e9;
}

.icon-sizes {
  height: 30px;
  width: 30px;
}

.pricing-head {
  text-align: center;
  font-family: cursive;
  font-weight: 900;
  font-size: 22px;
  padding: 10px 30px;
  /* border: 2px solid #5c5757; */
  border-radius: 10px;
  background: linear-gradient(to bottom, #bbbb73, #4d1f1f) text;
  -webkit-background-clip: text;
  color: transparent;
  transition: transform 0.3s ease-in-out;
}

.pricing-head:hover {
  background: linear-gradient(to bottom, #765151, #f4f4cc) text;
}
.pricing-card-container-individual {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  justify-items: center;
}

.pricing-head {
  transition: background 0.3s ease;
}

.pricing-card-container:hover .pricing-head {
  background: linear-gradient(to bottom, #765151, #f4f4cc);
  -webkit-background-clip: text;
  color: transparent;
}

@media (max-width: 1024px) {
  .pricing-card-container-individual {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .pricing-card-container-individual {
    grid-template-columns: repeat(1, 1fr);
  }
}
.pack-heading-main {
  margin: 20px 0;
  text-align: center;
  color: #333; /* Darker shade for better contrast */
  font-size: 26px; /* Larger font size for a heading */
  font-weight: 700; /* Bolder font weight */
  font-family: "Arial", sans-serif;
  text-decoration: underline;
}
