.noCreditsTextBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.noCreditsText {
  font: normal 18px/24px "Light", sans-serif !important;
  color: #333;
  text-align: center;
  margin-bottom: 16px;
}

.purchaseButton {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font: normal 14px/24px "Light", sans-serif !important;
  transition: background-color 0.3s;
}

.purchaseButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.purchaseButton:hover:not(:disabled) {
  background-color: #0056b3;
}

.streamErrorModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.streamErrorModalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  font: normal 16px/24px "Medium", sans-serif;
}

.regionErrorText {
  color: red;
  font: normal 16px/24px "Regular", sans-serif;
}

.streamCloseBtn {
  padding: 6px 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font: normal 16px/24px "Regular", sans-serif;
  transition: background-color 0.3s ease;
}

.streamCloseBtn:hover {
  background-color: #0056b3;
}

.streamCloseBtn:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

.streamCloseBtn:active {
  background-color: #004085;
  transform: scale(0.98);
}

/* HTML: <div class="region-fetching-loader"></div> */
.regionFetchingLoader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
}
.regionFetchingLoader::before,
.regionFetchingLoader::after {
  content: "";
  grid-area: 1/1;
  --c: no-repeat radial-gradient(farthest-side, #25b09b 92%, #0000);
  background: var(--c) 50% 0, var(--c) 50% 100%, var(--c) 100% 50%,
    var(--c) 0 50%;
  background-size: 12px 12px;
  animation: l12 1s infinite;
}
.regionFetchingLoader::before {
  margin: 4px;
  filter: hue-rotate(45deg);
  background-size: 8px 8px;
  animation-timing-function: linear;
}

@keyframes l12 {
  100% {
    transform: rotate(0.5turn);
  }
}

.form-region-control {
  margin: 16px 0;
  display: flex;
  flex-direction: column;
}

.form-region-label {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
}

.form-region-select {
  width: 400px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  transition: border-color 0.3s ease;
}

.form-region-select:focus {
  outline: none;
  border-color: #1e90ff; /* Blue focus border */
  box-shadow: 0 0 4px rgba(30, 144, 255, 0.5);
}
