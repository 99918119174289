.sidebarContainerStyle {
  display: flex;
  flex-direction: column;
  position: relative;
}

/* Menu button */
.menuButton {
  display: none;
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  border-radius: 4px;
  margin: 10px 0px;
  z-index: 1001;
}

/* Drawer for showing menu items on small screens */
.drawer {
  display: none;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  justify-content: center; /* Center items vertically */
  align-items: center; /* Center items horizontally */
}

.drawerOpen {
  display: flex; /* Show drawer when open */
}

.menuItemsContainer {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 90%; /* Limit the height to prevent overflow */
  overflow-y: auto; /* Enable scrolling if content exceeds container height */
}

/* Menu Item styles */
.menuItemStyle,
.activeMenuItemStyle {
  padding: 15px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 18px;
  background-color: #f9f9f9;
  border-radius: 5px;
  text-align: center;
}

.activeMenuItemStyle {
  background-color: #007bff;
  color: white;
}

.menuIconStyle {
  display: block;
  margin-bottom: 5px;
}

.menuItemTextStyle {
  margin: 0;
}

/* Responsive styles */
@media (max-width: 800px) {
  .menuButton {
    display: block; /* Show button below 800px */
  }

  .menuItemsContainer {
    flex-direction: column;
  }

  .drawer {
    display: none; /* Keep drawer hidden by default */
  }

  .drawerOpen {
    display: flex; /* Show drawer when button is clicked */
  }
}

@media (min-width: 801px) {
  .menuButton {
    display: none; /* Hide button above 800px */
  }

  .drawer {
    display: none; /* Ensure the drawer is hidden on larger screens */
  }
}
