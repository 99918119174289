@import url('https://fonts.googleapis.com/css2?family=Smooch+Sans:wght@100..900&family=Teko:wght@300..700&display=swap');

.doorContainerT2 {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.advertismentP {
  width: 760px;
}
.advertismentL {
  width: 450px;
}

.advertisementContainer {
  margin: auto;
  background-color: #f2f2f2;
  padding: 10px 0;
  overflow: hidden;
  white-space: nowrap;
}

.advertisement {
  white-space: nowrap;
  display: inline-block;
  animation: scrollAd 20s linear infinite;
  font-size: 20px;
  font-weight: bold;
  color: #333;
}
@keyframes scrollAd {
  0% {
    transform: translateX(100%); /* Start from right */
  }
  100% {
    transform: translateX(-100%); /* End at left */
  }
}
.invitationPopOver {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.doubleDoorContainerT2 {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
}

/* Modal overlay */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content */
.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.modalTitle {
  font: normal 18px/24px "Regular", sans-serif !important;
}

.modalSubTitle {
  font: normal 16px/24px "Regular", sans-serif !important;
  color: green;
}

.inputField {
  width: 100%;
  padding: 10px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.errorMessage {
  color: red;
  font-size: 14px;
  margin-bottom: 12px;
  font: normal 14px/24px "Regular", sans-serif !important;
}

.submitBtn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submitBtn {
  background-color: #f44336;
  color: white;
}

.submitBtn:hover {
  background-color: #d32f2f;
}

.doorT2 {
  width: 50vw; /* Each door takes half the screen */
  height: 100vh;
  position: absolute;
  top: 0;
  background: linear-gradient(
    45deg,
    #d4af37,
    #f0c674,
    #b88a4e,
    #c68e17
  ); /* Gold-like gradient */
  background-size: 400% 400%; /* Make the gradient more dynamic */
  animation: gradientAnimation 5s ease-in-out infinite; /* Optional, for a moving gradient effect */
  transition: transform 1.5s ease-in-out; /* Smooth transition for door opening */
}

/* Optional: Adding moving gradient animation for a more dynamic effect */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.leftDoorT2 {
  left: 0;
  transform-origin: left; /* Rotate from the left edge */
}

.rightDoorT2 {
  right: 0;
  transform-origin: right; /* Rotate from the right edge */
}

.leftDoorT2.openT2 {
  transform: rotateY(-90deg); /* Rotate the left door openT2 */
}

.rightDoorT2.openT2 {
  transform: rotateY(90deg); /* Rotate the right door openT2 */
}

.welcomeTextT2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
}

.welcomeTextT2 h1 {
  font-size: 2.5rem; /* Larger font size for emphasis */
  color: #f0e68c; /* A soft gold color to match the wedding theme */
  font-family: "Dancing Script", cursive; /* Elegant font for a wedding vibe */
  opacity: 0;
  animation: welcomeAnimation 3s ease-in-out forwards; /* Animation for h1 text */
  letter-spacing: 3px; /* Adds spacing between letters for a more elegant look */
  transform: scale(1.1); /* Slightly enlarged initially for dramatic effect */
  background-image: linear-gradient(45deg, #d4af37, #f0c674, #b88a4e, #c68e17);
  -webkit-background-clip: text; /* Clips the background to the text */
  color: transparent; /* Makes the text transparent to reveal the gradient background */
}

@keyframes welcomeAnimation {
  0% {
    opacity: 0;
    transform: scale(0.7); /* Start smaller */
  }
  50% {
    opacity: 1;
    transform: scale(1.1); /* Slightly larger */
  }
  100% {
    opacity: 1;
    transform: scale(1); /* Final size */
  }
}

/* h1 Animation to scale and fade in */
@keyframes welcomeAnimation {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.mainContentT2.showT2 {
  opacity: 1;
}
.navLogoT2 {
  height: 40px;
  width: 150px;
}
/* General Styles */
.container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #9400d314;
  font-family: "Teko", serif;

}

/* Navbar */
.nav1 {
  position: absolute;
  top: 10px; /* Adjust as needed */
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures space between logo and h1 */
  padding: 10px 20px; /* Adds spacing */
}
.t3invitaitonImage {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  height: 500px;
  max-height: 700px;
  width: auto;
  transition: transform 0.3s ease-in-out;
  object-fit: cover;
  cursor: pointer;
  margin: 0 auto;
}
.t3invitaitonImage:hover {
  transform: scale(1.01);
}
/* Banner */
.bgbanner {
  display: flex;
  background-size: cover;
  background-position: center;
  min-height: 700px;
  max-height: 800px;
  padding-top: 20px;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 30px;
  font-weight: bold;
  padding-left: 0;
  padding-right: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Invitation Card */
.invi1bg {
  /* background-color: beige; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.invi1headings {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}

.invi1headings img {
  margin-bottom: 30px;
  max-width: 90%;
}

.invi1content {
  margin-top: 30px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px solid #ff6f61;
  width: 80%;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #333;
}

.invi1content h1 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #ff6f61;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.invi1content p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  text-align: center;
}

.detailscontainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.detailbox {
  flex: 1;
  min-width: 200px; /* Minimum width for smaller screens */
  margin: 10px;
  padding: 15px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.detailbox:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.detailboxicon {
  font-size: 28px;
  color: #ff6f61;
  margin-bottom: 10px;
}

.detailbox h2 {
  font-size: 18px;
  margin: 10px 0;
  color: #555;
}

.detailbox p {
  font-size: 16px;
  margin: 0;
  color: #777;
}

.countdowncontainer {
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  background-color: #c23ff9cc;
  width: auto;
  border-radius: 14px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
  padding: 30px;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.countdownbox {
  padding: 15px;
  background-color: #f3f3f3;
  border-radius: 8px;
  text-align: center;
  min-width: 70px;
}

.countdownbox p {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.countdownbox span {
  font-size: 14px;
  color: #666;
}

/* Invitation Video */
.invivideo {
  text-align: center;
  /* padding: 40px 20px; */
  /* background: linear-gradient(135deg, #f9f9f9, #e0f7fa); */
}

.videocontainer {
  max-width: 1400px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  background: #000;
}

.videocontainer video {
  width: 100%;
  height: auto;
  border-radius: 10px;
  display: block;
}

/* Online Video */
.Onvideo {
  text-align: center;
  padding: 40px 20px;
  /* background: linear-gradient(135deg, #f9f9f9, #e0f7fa); */
  /* background: linear-gradient(135deg, #04f6f260, #f9f9f9); */
  font-family: "Georgia", serif;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .bgbanner {
    min-height: 400px;
    font-size: 24px;
  }

  .invi1content {
    padding: 15px;
  }

  .detailscontainer {
    flex-direction: column; /* Stack boxes vertically on smaller screens */
  }

  .detailbox {
    max-width: 100%; /* Full width for smaller screens */
    margin: 10px 0;
  }

  .countdowncontainer {
    /* flex-direction: column; Stack countdown boxes vertically */
    gap: 10px;
    padding: 10px;
  }

  .countdownbox {
    max-width: 100%; /* Full width for smaller screens */
  }
  .countdownbox p {
    font-size: 24px;
  }

  .countdownbox span {
    font-size: 16px;
  }

}

@media (max-width: 480px) {
  .bgbanner {
    min-height: 300px;
    font-size: 20px;
  }

  .invi1content h1 {
    font-size: 24px;
  }

  .invi1content p {
    font-size: 14px;
  }

  .detailbox h2 {
    font-size: 16px;
  }

  .detailbox p {
    font-size: 14px;
  }

  .countdownbox p {
    font-size: 20px;
  }

  .countdownbox span {
    font-size: 12px;
  }
}

/* Images */
.galleryContainer {
  max-width: 800px;
  position: relative;
  margin: auto;
  padding: 10px 0px;
}

.slider {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageContainer {
  padding: 10px;
}

.thumbnail {
  width: 100%;
  height: 300px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 5px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Ensure the overlay covers the full width */
  height: 100vh; /* Ensure the overlay covers the full height */
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.fullImage {
  max-width: 90%; /* Ensure the image fits within 90% of the overlay's width */
  max-height: 90%; /* Ensure the image fits within 90% of the overlay's height */
  object-fit: contain; /* Maintain aspect ratio and show the full image */
}
.galleryContainer {
  max-width: 800px;
  position: relative;
  margin: auto;
  padding: 10px 0px;
}

.slider {
  display: flex;
  justify-content: center;
  align-items: center;
}


.thumbnail {
  height: 300px;
  cursor: pointer;
  object-fit: cover;
  animation: thumbnail 8s ease-in-out infinite;
  border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  box-shadow: rgba(227, 110, 240, 0.812) 0px 10px 20px;
}

@keyframes thumbnail {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Ensure the overlay covers the full width */
  height: 100vh; /* Ensure the overlay covers the full height */
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.fullImage {
  max-width: 90%; /* Ensure the image fits within 90% of the overlay's width */
  max-height: 90%; /* Ensure the image fits within 90% of the overlay's height */
  object-fit: contain; /* Maintain aspect ratio and show the full image */
}

/* Footer */
.L302F {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(14, 6, 6, 0.942);
  padding: 15px;
  font-size: 28px;
  text-align: center;
  width: 100%;
}

.L302F p {
  margin: 0;
  font-weight: 500;
}

@media (max-width: 768px) {
  .invi1content{
    width: 90%;
  }
  .videocontainer{
    width: 90%;
  }
  .navLogoT2{
    height: 20px;
    width: 70px;
  }
  .thumbnail{
    max-width: 500px;
  }
  .galleryContainer{
    width: 500px;
  }
  .countdownbox{
    min-width: 60px;
  }
}


@media (max-width: 450px) {  
  .galleryContainer{
    width: 300px;
    max-width: 350px;
  }
  /* .countdownbox{
    min-width: 80px;
  } */
}


@media (max-width: 360px) {  
  
  .countdownbox{
    min-width: 100px;
  }
  .galleryContainer{
    width: 280px;
    max-width: 300px;
  }
}