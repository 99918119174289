@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Joti+One&display=swap");

.mainContainerT2 {
  background-image: url("../../../../public/grunge-white-surface-rough-background-textured.jpg");
  background-size: contain;
  height: 100vh;
  overflow: auto;
  min-width: 350px;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
.eachSectionT2 {
  border: 5px rgb(217, 216, 216) solid;
  padding: 50px 0px;
}
.doorContainerT2 {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.doubleDoorContainerT2 {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
}

.doorT2 {
  width: 50vw; /* Each door takes half the screen */
  height: 100vh;
  position: absolute;
  top: 0;
  background: linear-gradient(
    45deg,
    #d4af37,
    #f0c674,
    #b88a4e,
    #c68e17
  ); /* Gold-like gradient */
  background-size: 400% 400%; /* Make the gradient more dynamic */
  animation: gradientAnimation 3.5s ease-in-out infinite; /* Optional, for a moving gradient effect */
  transition: transform 3.5s ease-in-out; /* Smooth transition for door opening */
}

/* Optional: Adding moving gradient animation for a more dynamic effect */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.leftDoorT2 {
  left: 0;
  transform-origin: left; /* Rotate from the left edge */
}

.rightDoorT2 {
  right: 0;
  transform-origin: right; /* Rotate from the right edge */
}

.leftDoorT2.openT2 {
  transform: rotateY(-90deg); /* Rotate the left door openT2 */
}

.rightDoorT2.openT2 {
  transform: rotateY(90deg); /* Rotate the right door openT2 */
}

.welcomeTextT2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
}

.welcomeTextT2 h1 {
  font-size: 2.5rem; /* Larger font size for emphasis */
  color: #f0e68c; /* A soft gold color to match the wedding theme */
  font-family: "Dancing Script", cursive; /* Elegant font for a wedding vibe */
  opacity: 0;
  animation: welcomeAnimation 3s ease-in-out forwards; /* Animation for h1 text */
  letter-spacing: 3px; /* Adds spacing between letters for a more elegant look */
  transform: scale(1.1); /* Slightly enlarged initially for dramatic effect */
  background-image: linear-gradient(45deg, #d4af37, #f0c674, #b88a4e, #c68e17);
  -webkit-background-clip: text; /* Clips the background to the text */
  color: transparent; /* Makes the text transparent to reveal the gradient background */
}

@keyframes welcomeAnimation {
  0% {
    opacity: 0;
    transform: scale(0.7); /* Start smaller */
  }
  50% {
    opacity: 1;
    transform: scale(1.1); /* Slightly larger */
  }
  100% {
    opacity: 1;
    transform: scale(1); /* Final size */
  }
}

/* h1 Animation to scale and fade in */
@keyframes welcomeAnimation {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Animation to fade out the h1 and transition to the main content */
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

/* Main content (this will be shown after the animation) */
.mainContentT2 {
  overflow: auto;
  text-align: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  color: black;
}

.mainContentT2.showT2 {
  opacity: 1;
}

/* Heading Text Styling */
.bannerHeadingT2 {
  font-family: "Dancing Script", cursive; /* Stylish cursive font */
  font-size: 3rem; /* Large font size */
  color: #2c3e50; /* Deep, contrasting color */
  margin-bottom: 20px; /* Space between heading and image */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for text readability */
  font-weight: bold; /* Bold text */
  letter-spacing: 2px; /* Slightly spaced letters for a refined look */
}

/* Image Styling */
.bannerSectionT2 img {
  border: 20px white solid;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
  object-fit: cover;
}

.bannerSectionT2 img:hover {
  transform: scale(1.01); /* Slight zoom effect when hovering */
}

/* Add this to your .css file, e.g. `App.css` or `WeddingInvitation.css` */
/* .staticHeroS2T2 {
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
} */

.staticMainBoxT2 {
  max-width: 1566px;
  margin: 0 auto;
}

.staticInnerBoxT2 {
  max-width: 1268px;
  margin: 0 auto;
}
.rowT2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wpoStaticHeroTextWrapT2 {
  padding: 30px;
  border-radius: 430px 430px 0px 0px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: relative;
}

@media (max-width: 575px) {
  .wpoStaticHeroTextWrapT2 {
    padding: 15px;
  }
}

.shapeT2 {
  position: absolute;
  left: -180px;
  top: 15px;
  z-index: 1;
}

.shapeT2 img {
  animation: leafAniamtion 12s ease-in infinite;
}

@media (max-width: 991px) {
  .shapeT2 {
    display: none;
  }
}

.shape2T2 {
  position: absolute;
  right: -180px;
  top: 15px;
  z-index: 1;
}

.shape2T2 img {
  animation: leafAniamtion 12s ease-in infinite;
}

@media (max-width: 991px) {
  .shape2T2 {
    display: none;
  }
}

.staticHeroImgT2 {
  border-radius: 230px 230px 0px 0px;
  background: #fff;
  padding: 20px;
  z-index: 11;
  position: relative;
  max-width: 460px;
  right: -75px;
  margin-top: 100px;
}

@media (max-width: 991px) {
  .staticHeroImgT2 {
    right: 0;
    box-shadow: 0px 2px 12px 2px rgba(4, 0, 52, 0.1);
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .staticHeroImgT2 img {
    width: 100%;
  }
}

.staticHeroImgT2 img {
  border-radius: 230px 230px 0px 0px;
}

.col {
  padding: 0;
}

@media (min-width: 992px) {
  .col:lastchildt2 .staticHeroImgT2 {
    right: auto;
    left: -75px;
  }

  @media (max-width: 991px) {
    .col:lastchildt2 .staticHeroImgT2 {
      left: 0;
    }
  }
}

.wpoStaticHeroTextBoxT2 {
  border-radius: 430px 430px 0px 0px;
  background: #ecebe5;
  padding: 160px 150px 98px;
  text-align: center;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 550px;
  gap: 20px;
}

@media (max-width: 1399px) {
  .wpoStaticHeroTextBoxT2 {
    padding: 100px 100px 50px;
  }
}

@media (max-width: 1199px) {
  .wpoStaticHeroTextBoxT2 {
    padding: 80px 80px 46px;
  }
}

@media (max-width: 991px) {
  .wpoStaticHeroTextBoxT2 {
    padding: 90px 70px 40px;
  }
}

@media (max-width: 767px) {
  .wpoStaticHeroTextBoxT2 {
    padding-top: 120px;
  }
}

@media (max-width: 575px) {
  .wpoStaticHeroTextBoxT2 {
    padding: 60px 20px 58px;
  }
}
.navHeading {
  font-size: clamp(0.5rem, 2vw, 1rem);
}

.slideTitleT2 h2 {
  color: #605933;
  text-align: center;
  font-size: clamp(0.5rem, 3vw, 2rem);
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 30px;
  line-height: 85px;
  /* display: block;
  text-align: center;
  white-space: pre-line;
  word-break: break-word; */
}

@media (max-width: 1500px) {
  .slideTitleT2 h2 {
    margin-bottom: 30px;
    line-height: 75px;
  }
}

@media (max-width: 1399px) {
  .slideTitleT2 h2 {
    margin-bottom: 30px;
    line-height: 65px;
  }
}

@media (max-width: 1100px) {
  .slideTitleT2 h2 {
    /* font-size: 28px; */
    margin-bottom: 20px;
    line-height: 35px;
  }
}

@media (max-width: 575px) {
  .slideTitleT2 h2 {
    /* font-size: 18px; */
    margin-bottom: 20px;
    line-height: 30px;
  }
}
@media (max-width: 360px) {
  .slideTitleT2 h2 {
    font-size: 18px;
    margin-bottom: 14px;
    line-height: 25px;
  }
}

.slideTextT2 p {
  font-size: clamp(0.5rem, 3vw, 2rem);
  color: #878367;
  margin: 0;
  margin-bottom: 30px;
}

@media (max-width: 1199px) {
  .slideTextT2 p {
    /* font-size: 18px; */
    margin-bottom: 25px;
  }
  .bannerHeadingT2 {
    font-size: 2.5rem;
  }
}

@media (max-width: 991px) {
  .slideTextT2 p {
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .bannerHeadingT2 {
    font-size: 2.25rem;
  }
}

@media (max-width: 530px) {
  .slideTextT2 p {
    /* font-size: 12px; */
    margin-bottom: 10px;
  }
  .bannerHeadingT2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 360px) {
  .slideTextT2 p {
    /* font-size: 10px; */
    margin-bottom: 6px;
  }
  .bannerHeadingT2 {
    font-size: 1rem;
  }
}

.slideDateT2 {
  margin-bottom: 0px;
}

.slideDateT2 p {
  font-size: 30px;
  line-height: 20px;
  color: #655f30;
  margin: 0;
  font-weight: 600;
}

@media (max-width: 1199px) {
  .slideDateT2 p {
    font-size: 28px;
  }
}
@media (max-width: 1000px) {
  .slideDateT2 p {
    font-size: 26px;
  }
}
@media (max-width: 899px) {
  .slideDateT2 p {
    font-size: 18px;
  }
}

@media (max-width: 530px) {
  .slideDateT2 p {
    font-size: 18px;
  }
}
@media (max-width: 530px) {
  .slideDateT2 {
    margin-bottom: 6px;
  }
  .slideDateT2 p {
    font-size: 18px;
  }
}
@media (max-width: 360px) {
  .slideDateT2 {
    font-size: 18px;
  }
}
.slideTimeT2 {
  margin-top: 10px;
}
.slideTimeT2 p {
  font-size: 30px;
  line-height: 20px;
  color: #655f30;
  font-weight: 600;
}

@media (max-width: 1199px) {
  .slideTimeT2 p {
    font-size: 28px;
  }
}

@media (max-width: 899px) {
  .slideTimeT2 p {
    margin: 0;
    font-size: 18px;
  }
}

@media (max-width: 530px) {
  .slideTimeT2 p {
    font-size: 18px;
  }
}
@media (max-width: 360px) {
  .slideTimeT2 {
    font-size: 18px;
  }
}

.countdownContainerT2 {
  padding: 0px 50px;
  display: grid;
  grid-template-columns: repeat(4, 0fr);
  gap: 34px;
  justify-items: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

@media (max-width: 1020px) {
  .countdownContainerT2 {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
  }
}

@media (max-width: 480px) {
  .countdownContainerT2 {
    grid-template-columns: 1fr; /* 1 column on very small screens */
  }
}

/* .countdownBoxT2 {
  background-color: #f3f3f3;
  border-radius: 8px;
  text-align: center;
  min-width: 70px;
  transition: transform 0.3s ease-in-out;
} */
.countdownBoxT2:hover {
  transform: scale(1.1);
}

.countdownBoxT2 p {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.countdownBoxT2 span {
  font-size: 14px;
  color: #666;
}

.countdownBoxT2 {
  padding: 50px;
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
  border: 20px solid white; /* Base border */
  border-radius: 100px 100px 10px 10px; /* Circular shape */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #ecebe5; /* Initial state */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #f3f3f3;
  /* border-radius: 8px; */
  /* text-align: center; */
  min-width: 70px;
  transition: transform 0.3s ease-in-out;
}

.countdownBoxT2 p {
  font-size: 1.85rem;
  font-weight: bold;
  margin: 0;
}

.countdownBoxT2 span {
  font-size: 0.9rem;
  color: #666;
}

@media (max-width: 480px) {
  .countdownBoxT2 {
    border: 15px solid white;
  }
}

.video {
  border: 20px white solid;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

/* Base styles for the navbar */
.navbarT2 {
  background-color: #333; /* Dark background for the navbar */
  padding: 30px;
  text-align: center;
  position: sticky;
  top: 0; /* Stick to the top of the viewport */
  z-index: 1000; /* Make sure the navbar stays on top of other content */
}
.navbarMobileT2 {
  background-color: #333; /* Dark background for the navbar */
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fontSizeT2 {
  margin: 0px;
  color: #ecebe5;
}
.fontSizeT2 {
  font-size: clamp(0.5rem, 3vw, 1rem);
}
.navLogoT2 {
  height: 40px;
  width: 150px;
}
.navLogoContainerT2 {
  position: absolute;
  top: 30%;
}
@media (max-width: 768px) {
  .navLogoT2 {
    height: 20px;
    width: 70px;
  }
  .navLogoContainerT2 {
    position: absolute;
    top: 42%;
  }
}
@media (max-width: 400px) {
  .navLogoT2 {
    width: 65px;
  }
  .navLogoContainerT2 {
    position: absolute;
    top: 40%;
  }
  .navbarMobileT2,
  .navbarT2 {
    padding: 10px 30px;
  }
}

/* Navbar list and links
  .navbar-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  
  .navbar-list li {
    margin: 0 20px;
  }
  
  .navbar-link {
    color: #ccc; 
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .navbar-link:hover {
    background-color: #f39c12; 
    color: #fff;
  }
  
  @media (max-width: 768px) {
    .navbar-list {
      display: none; 
      flex-direction: column; 
      align-items: center;
      width: 100%;
      background-color: #333; 
      position: absolute;
      top: 60px; 
      left: 0;
      padding: 20px 0;
    }
  
    .navbar-list.active {
      display: flex; 
    }
  
    .navbar-list li {
      margin: 10px 0; 
    }
  
    .navbar-menu {
      display: block; 
      cursor: pointer;
      padding: 10px;
      position: absolute;
      top: 5px;
      right: 20px;
    }
  
    .navbar-menu div {
      width: 25px;
      height: 3px;
      background-color: white;
      margin: 5px 0;
      transition: all 0.3s ease;
    }
  
    .navbar-menu.active div:nth-child(1) {
      transform: rotate(45deg) translate(5px, 5px);
    }
  
    .navbar-menu.active div:nth-child(2) {
      opacity: 0;
    }
  
    .navbar-menu.active div:nth-child(3) {
      transform: rotate(-45deg) translate(5px, -5px);
    }
  } */

.galleryImgT2 {
  height: 400px;
  width: 300px !important; /* Set width explicitly */
  border: 20px solid white;
  border-radius: 200px 200px 0px 0px; /* Rounded top corners */
  overflow: hidden; /* Prevents image from overflowing the border */
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 20px 10px;
}

.galleryImgT2 img {
  height: 100%;
  width: 100%;
  object-fit: cover; /* Ensures the image covers the container */
  transition: transform 0.3s ease; /* Smooth transition for scale/tilt effect */
  opacity: 0.7;
}

.galleryImgT2:hover img {
  transform: scale(1.1) rotate(5deg); /* Scale and tilt effect */
  opacity: 1;
}

/* Optional: Adding a shadow for some depth */
.galleryImgT2:hover img {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

@media (max-width: 850px) {
  .galleryImgT2 {
    height: 250px;
    width: 200px !important;
  }
}

@media (max-width: 400px) {
  .galleryImgT2 {
    height: 200px;
    width: 150px !important;
  }
}

.videoT2 {
  border: 20px white solid;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.frameContainer {
  width: 80%;
  margin: auto;
}
.frameContainer iframe {
  border: 20px solid white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.frameContainer video {
  border: 20px solid white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.videoContainerT4 {
  width: 90%;
  height: auto;
  margin: auto;
}

.cedarvilleCursiveRegularT2 {
  font-size: clamp(0.5rem, 3vw, 2rem);
  font-family: "Joti One", serif;
  font-weight: 400;
  font-style: normal;
}
