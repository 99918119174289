.pricingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  background-color: #f5f7fa;
  min-height: 100vh;
  flex-wrap: wrap;
  gap: 20px;
}

/* Button Container */
.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

/* Styling for each navigation button */
.navButton {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: none; /* No shadow by default */
}

.navButton:hover {
  background-color: #0056b3;
}

.navButton:focus {
  outline: none;
}

/* Active button styling with shadow */
.navButton.active {
  background-color: #28a745;
  border-color: #28a745;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.3); /* Shadow effect around active button */
}

.navButton.active:hover {
  background-color: #218838;
}

/* Payment List container with shadow */
.listContainer {
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for the list container */
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
}

/* Styling for each pricing card */
.pricingCard {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  width: 250px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  transition: transform 0.3s ease;
}

.pricingCard:hover {
  transform: scale(1.05);
}

/* Text styling for prices */
.actualPrice {
  font-size: 14px;
  color: #999;
  text-decoration: line-through;
}

.salePrice {
  font-size: 18px;
  color: #28a745;
  font-weight: bold;
}

.featuresBlock {
  margin-top: 10px;
}

.feature {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.feature p {
  margin-left: 5px;
}

.payBtn {
  margin-top: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.payBtn:disabled {
  background-color: #d6d6d6;
  cursor: not-allowed;
}

.payBtn:hover {
  background-color: #0056b3;
}

.pricingCard {
  background: white;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 350px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 0 14px;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
}

.pricingCardTitle {
  text-transform: capitalize;
  background-color: #3498db;
  color: #ffffff;
  width: 100%;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font: normal 18px/16px "Regular", sans-serif;
}

/* .pricingCardDescription {
  width: 100%;
  background-color: #29a4b9;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 22px;
  font-weight: 300;
} */

.actualPrice {
  color: #3498db;
  font: normal 36px/24px "Medium", sans-serif;
}

.salePrice {
  font: normal 36px/24px "Medium", sans-serif;
}

.featuresBlock {
  list-style: none;
  color: #777;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.feature {
  display: flex;
  align-items: center;
  gap: 8px;
}

.ProductSpecStyle {
  font: normal 16px/24px "Light", sans-serif;
}

.payBtn {
  width: 100%;
  background: #29a4b9;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font: normal 18px/24px "Light", sans-serif;
  &:hover {
    background-color: #2980b9;
  }
}

/* Styling for the dialog container */
.paymentDialog {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Styling for dialog title */
.dialogTitle {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

/* Styling for the content section */
.dialogContent {
  padding: 10px 0px 20px 0px;
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
}

.priceItem {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #555;
  margin: 0;
}

.totalPrice {
  font-weight: bold;
  color: #28a745; /* Green color for Total Price */
}

/* Styling for the button */
.dialogFooter {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.paymentBtn {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

.paymentBtn:hover {
  background-color: #218838; /* Darker green on hover */
}
.upiDialog {
  position: absolute;
  display: flex;
  flex-direction: column;
}
.qrImage {
  height: 250px;

  width: 200px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.closeButton {
  position: absolute;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 9;
}
.qrImage {
  height: auto;
  width: 250px;
  display: inline !important;
  border-radius: 10px;
}
.instructionText {
  font-size: 13px;
  color: #333;
  margin-top: 25px;
  margin-bottom: 15px;
}
@media (max-width: 600px) {
  .instructionText {
    font-size: 10px;
  }
  .qrImage {
    width: 100%;
    height: auto;
    max-width: 200px;
  }
  .dialogTitle {
    font-size: 18px;

    margin-bottom: 10px;
  }
  .priceItem {
    font-size: 12px;
    margin: 5px;
  }
  .totalPrice {
    font-size: 12px;
  }
  .paymentBtn {
    padding: 10px 15px;
    font-size: 10px;
    margin-bottom: 0px;
  }
  .footer {
    font-size: 10px;
    margin: 10px 0px;
  }
  .phoneNumber {
    font-size: 12px;
  }
}

.phoneNumber {
  font-size: 16px;
  font-weight: bold;
  color: #0078d4;
  margin-top: 8px;
}
.prevButton,
.nextButton {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  z-index: 1;
}

.prevButton {
  left: 10px;
}

.nextButton {
  right: 10px;
}
