@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Coming+Soon&family=Nothing+You+Could+Do&family=Permanent+Marker&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Marck+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap");
.cedarvilleCursiveRegularT4 {
  font-family: "Cedarville Cursive", serif;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(1rem, 4vw, 2rem);
}

.marckScriptRegularT4 {
  font-family: "Marck Script", serif;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(1rem, 4vw, 2rem);
}

.flowerContainerT4 {
  margin: 0;
  padding: 0;
}

.flowerContainerT4 {
  position: relative;
  width: 100%;
}

.flowerT4 {
  z-index: 5;
  position: absolute;
  font-size: 2rem; /* Size of the flower emojis */
  animation: fallT4 linear infinite;
}

/* Define different animation durations and delays for each flower */
.flowerT4:nth-child(1) {
  left: 10%;
  animation-duration: 8s;
  animation-delay: 0s;
}

.flowerT4:nth-child(2) {
  left: 25%;
  animation-duration: 10s;
  animation-delay: 2s;
}

.flowerT4:nth-child(3) {
  left: 40%;
  animation-duration: 12s;
  animation-delay: 1s;
}

.flowerT4:nth-child(4) {
  left: 55%;
  animation-duration: 9s;
  animation-delay: 3s;
}

.flowerT4:nth-child(5) {
  left: 70%;
  animation-duration: 11s;
  animation-delay: 0.5s;
}

.flowerT4:nth-child(6) {
  left: 85%;
  animation-duration: 7s;
  animation-delay: 4s;
}

.flowerT4:nth-child(7) {
  left: 95%;
  animation-duration: 13s;
  animation-delay: 1.5s;
}

/* Keyframes for the falling animation */
@keyframes fallT4 {
  0% {
    transform: translateY(-100px) rotate(0deg);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateY(100vh) rotate(360deg);
    opacity: 0;
  }
}

.textT4 {
  font-size: 3rem;
  color: black;
}

/* .head {
  font-size: 3rem;
  text-transform: uppercase;
  font-family: "Permanent Marker", serif;
  letter-spacing: 4px;
  transition: 700ms ease;
  font-variation-settings: "wght" 311;
  color: black;
  outline: none;
  text-align: center;
  font-weight: 400;
}
.headd:hover {
  font-variation-settings: "wght" 582;
  letter-spacing: 6px;
  font-weight: 700;
} */

.wrapperT4 {
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.containerT4 {
  display: flex;
  flex-direction: column;
  float: left;
  justify-content: center;
}

.containerT4 h1 {
  font-size: clamp(2rem, 4vw, 4rem);
  text-transform: uppercase;
  font-family: "Permanent Marker", serif;
  animation: text-shadowT4 1.5s infinite ease-in-out;
  font-weight: 900;
  line-height: 1;
}

.containerT4 h1:hover {
  animation-play-state: paused;
}

.aT4 {
  color: #024794;
}

.aT4:hover {
  text-decoration: none;
}

@keyframes text-shadowT4 {
  0% {
    transform: translateY(0);
    text-shadow: 0 0 0 #0c2ffb, 0 0 0 #2cfcfd, 0 0 0 #fb203b, 0 0 0 #fefc4b;
  }

  20% {
    transform: translateY(-1em);
    text-shadow: 0 0.125em 0 #0c2ffb, 0 0.25em 0 #2cfcfd, 0 -0.125em 0 #fb203b,
      0 -0.25em 0 #fefc4b;
  }

  40% {
    transform: translateY(0.5em);
    text-shadow: 0 -0.0625em 0 #0c2ffb, 0 -0.125em 0 #2cfcfd,
      0 0.0625em 0 #fb203b, 0 0.125em 0 #fefc4b;
  }

  60% {
    transform: translateY(-0.25em);
    text-shadow: 0 0.03125em 0 #0c2ffb, 0 0.0625em 0 #2cfcfd,
      0 -0.03125em 0 #fb203b, 0 -0.0625em 0 #fefc4b;
  }

  80% {
    transform: translateY(0);
    text-shadow: 0 0 0 #0c2ffb, 0 0 0 #2cfcfd, 0 0 0 #fb203b, 0 0 0 #fefc4b;
  }
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}

.nothingYouCouldDoRegularT4 {
  font-family: "Nothing You Could Do", serif;
  font-weight: 400;
  font-style: normal;
}

.middleCouplePicT4 {
  max-width: 640px;
  position: relative;
  display: flex;
  left: 50px;
}

.middleCouplePicForEditT4 {
  max-width: 90%;
  position: relative;
  display: flex;
  margin: auto;
}

.middleCouplePicInnerT4 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.middleCouplePicInnerForEditT4 img {
  margin: auto;
  max-width: 340px;
  height: 450px;
  object-fit: cover;
  padding: 20px;
  background: rgb(255, 255, 255);
  border-radius: 50%; /* Ensures circular shape */
  margin: 0px auto 80px;
  position: relative;
  box-shadow: 0px 4px 50px rgba(255, 0, 150, 0.3); /* Default shadow */
  margin: 50px 0px 0px 0px;
}

.middleCouplePicInnerT4 img {
  margin: 50px 0px 150px 0px;
  max-width: 640px;
  padding: 20px;
  background: rgb(255, 255, 255);
  border-radius: 50%; /* Ensures circular shape */
  margin: 0px auto 80px;
  position: relative;
  box-shadow: 0px 4px 50px rgba(255, 0, 150, 0.3); /* Default shadow */
}

.middleCouplePicInnerForEditT4 img::before,
.middleCouplePicInnerT4 img::before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  background: linear-gradient(
    45deg,
    #ff4b2b,
    #ff416c,
    #ff6a00
  ); /* Gradient colors */
  border-radius: 50%;
  filter: blur(15px); /* Creates a glowing effect */
}

.shapeT4 img {
  position: absolute;
  left: -80px;
  top: -30px;
}
.shapeForEditT4 img {
  position: absolute;
  height: 200px;
  left: -11px;
  top: 40px;
  transform: rotate(8deg);
}
.shape2T4 img {
  position: absolute;
  right: -220px;
  bottom: -175px;
}
.shape2ForEditT4 img {
  position: absolute;
  height: 259px;
  left: 100px;
  bottom: -50px;
  transform: rotate(-23deg);
}

.videoContainerT4 {
  margin: 50px 0px;
  width: 100%;
  max-width: 800px; /* Adjust max-width as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: white; /* Light background */
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;
  margin-left: auto;
  margin-right: auto;
}

.videoContainerT4 video {
  width: 100%;
  height: auto;
}
.videoContainerT4Iframe {
  width: 100%;
  height: 550px;
  max-height: 550px;
}
@media (min-width: 400px) {
  .videoContainerT4Iframe {
    height: 250px;
  }
}
@media (min-width: 600px) {
  .videoContainerT4Iframe {
    height: 350px;
  }
}

@media (min-width: 800px) {
  .videoContainerT4Iframe {
    height: 450px;
  }
}

.outerCircleT4 {
  width: 240px;
  height: 240px;
  border-radius: 50%;
  position: relative;
  background: #f0f0f0; /* Light gray background for the clock face */
  border: 10px solid #333; /* Clock border */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); /* Add a shadow for depth */
}

.clockFaceT4 {
  width: 100%;
  height: 100%;
  position: relative;
}

.hourHandT4,
.minuteHandT4,
.secondHandT4 {
  position: absolute;
  background: #333; /* Dark color for clock hands */
  transform-origin: bottom center; /* Rotate from the bottom center */
}

.hourHandT4 {
  width: 8px;
  height: 60px;
  top: 60px;
  left: 116px;
  animation: rotate-hourT4 12s infinite linear; /* 12-hour rotation */
}

.minuteHandT4 {
  width: 6px;
  height: 90px;
  top: 30px;
  left: 117px;
  animation: rotate-minuteT4 6s infinite linear; /* 60-minute rotation */
}

.secondHandT4 {
  width: 4px;
  height: 100px;
  top: 20px;
  left: 118px;
  background: #ff0000; /* Red color for the second hand */
  animation: rotate-secondT4 1s infinite linear; /* 60-second rotation */
}

@keyframes rotate-hourT4 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate-minuteT4 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate-secondT4 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Main Container
.slideC {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.sliderContent img {
  max-width: 250px;
  max-height: 250px;
  object-fit: cover;
  border-radius: 12px;
}

.thumbnail-container {
  display: flex;
  gap: 5px;
  max-width: 800px;
  overflow-x: auto;
  padding: 10px 0;
  scroll-behavior: smooth; 
}

.thumbnail-container::-webkit-scrollbar {
  display: none;
}

.btns {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.btns .btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
}

.btns .btn:focus {
  outline: none;
}
 */

/* //galery */
/* Example CSS for responsiveness */
.galleryContainerT4 {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.imageContainerT4 {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.thumbnailT4 {
  width: 100%;
  height: auto;
  border-radius: 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .imageContainerT4 {
    max-width: 200px;
  }
}

@media (max-width: 480px) {
  .imageContainerT4 {
    max-width: 150px;
  }
}

.scrollingMessT4 {
  width: 400px; /* Ensure it takes 100% of the parent's width */
  margin: auto; /* Centers the child element */
  background-color: #f2f2f2; /* Light grey background */
  padding: 10px 0; /* Padding for vertical spacing */
  overflow: hidden; /* Prevent any overflow */
  white-space: nowrap; /* Prevent text from wrapping */
  box-sizing: border-box;
}

.advertisementT4 {
  white-space: nowrap;
  display: inline-block;
  animation: scrollAd 20s linear infinite;
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

@keyframes scrollAd {
  0% {
    transform: translateX(100%); /* Start from right */
  }
  100% {
    transform: translateX(-100%); /* End at left */
  }
}
.fontSizeT4 {
  font-size: clamp(0.5rem, 3vw, 1rem);
}
