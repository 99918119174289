.video-js-hls {
  width: 90% !important;
  height: 100% !important;
}
.video-js-hls .vjs-tech {
  width: 100%;
  height: 100%;
}

/* .vjs-error-display{
  display: none;
} */
/* 
@media (max-width: 900px) {
  .video-js-hls {
    width: 700px !important;
    height: 400px !important;
  }
}

@media (max-width: 768px) {
  .video-js-hls {
    width: 600px !important;
    height: 400px !important;
  }
}

@media (max-width: 600px) {
  .video-js-hls {
    width: 400px !important;
    height: 300px !important;
  }
}
*/
.font-size {
  font-size: 18px;
}
@media (max-width: 430px) {
  .font-size {
    font-size: 10px !important;
  }
  .video-js-hls {
    height: 100px !important;
  }
  .loader {
    width: 2em !important;
    height: 2em !important;
    background-color: red !important; /* For testing visibility */
    color: blue !important;
  }
}
