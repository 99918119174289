.headerContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  height: 60px;
  padding: 16px;
  position: fixed;
  z-index: 1002;
  margin-bottom: 10px;
}

.navbarLogo {
  font-weight: bold;
  font-size: 1.5rem;
  color: #4c51bf; /* Indigo color */
  cursor: pointer;
}

.avbarLogo:hover {
  transform: scale(1.05);
}

.sendCreditsTextContainer {
  display: flex;
  gap: 10px;
  padding: 10px;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
  border-radius: 6px;
  color: #4a5568;
  cursor: pointer;
  border: none;
}
@media (max-width: 768px) {
  .menuItemText {
    display: none;
  }
  .sendCreditsTextContainer {
    margin-left: auto;
  }
}

.headerContentStyle {
  color: white;
  font: 500 24px/24px "Medium", sans-serif;
}

.logoTextStyle {
  cursor: pointer;
  font-size: 24px;
}

/* .creditsBlockStyle{
  color: #ffffff;
} */

/* CreditsDropdown.css */
.dropdown {
  position: relative;
  display: inline-block;
}
.iconsspace {
  gap: 10px;
}
.dropdownButton {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.dropdownIcon {
  margin-left: 5px;
}

.dropdownContent {
  display: flex;
  gap: 8px;
  flex-direction: column;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1;
  right: 0;
}

.creditsIcon {
  font-size: 20px;
}

.creditOptionStyle {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 10px;
}

.creditOptionTextStyle {
  white-space: nowrap;
  font: 500 16px/24px "Light", sans-serif;
}
.appsidemenu {
  position: relative;
}
@media (max-width: 800px) {
  .dropdown {
    display: none;
  }
}

/* Container for the verified emails section */
.verifiedEmailsContainer {
  padding: 20px;
  margin: 20px auto;
  max-width: 600px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* The list styling (no bullets) */
.verifiedEmailsList {
  display: flex;
  gap: 5px;
  list-style-type: none; /* Remove default list styling */
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
  width: 100%;
}

/* Section title styling */
.sectionTitle {
  font-size: 0.75em;
  margin-bottom: 5px;
  color: #333;
}
.searchByAndManualBtn {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 10px;
}
@media (max-width: 600px) {
  .searchByAndManualBtn {
    flex-direction: column;
  }
}

/* Each list item style */
.emailItem {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background-color: #fff;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

/* Hover effect on each list item */
.emailItem:hover {
  background-color: #e9ecef;
}

/* Styling for the history icon */
.historyIcon {
  color: #28a745; /* Green color for the icon */
  font-size: 18px;
}

/* Button style for the email button */
.emailButton {
  background: none;
  border: none;
  color: #007bff;
  font-size: 12px;
  cursor: pointer;
  padding: 0;
  font-family: inherit;
  transition: color 0.3s ease;
}

/* Responsive design adjustments */
@media (max-width: 600px) {
  .verifiedEmailsContainer {
    padding: 15px;
    margin: 10px;
  }
}

/* HTML: <div class="loader"></div> */
.loaderTailSpin {
  width: 20px;
  aspect-ratio: 1;
  display: grid;
  animation: l14 4s infinite;
}
.loaderTailSpin::before,
.loaderTailSpin::after {
  content: "";
  grid-area: 1/1;
  border: 8px solid;
  border-radius: 50%;
  border-color: red red #0000 #0000;
  mix-blend-mode: darken;
  animation: l14 1s infinite linear;
}
.loaderTailSpin::after {
  border-color: #0000 #0000 blue blue;
  animation-direction: reverse;
}
@keyframes l14 {
  100% {
    transform: rotate(1turn);
  }
}

.creditsCount {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .creditsCount {
    flex-direction: column;
  }
}
