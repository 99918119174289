.pageContainer {
  position: relative;
  min-height: 100vh;
  background: linear-gradient(to bottom right, #eef2ff, #ffffff, #f3e8ff);
  padding: 4.5rem 1.2rem; /* pt-16 */
}
.textCustom {
  color: black;
  font-weight: 800;
  font-size: 1.5rem;
  margin: 1rem 0;
}

.contentCustom {
  font-size: 1rem; /* Equivalent to text-md (16px) */
  font-weight: 700; /* Equivalent to font-bold */
  padding-top: 4rem; /* Equivalent to pt-16 (64px top padding) */
}

.contentDiscription {
  font-family: Arial, sans-serif; /* Set font style */
  font-size: 18px; /* Set font size */
  font-weight: 400; /* Set font weight (normal) */
  line-height: 1.5; /* Set line height for readability */
  text-align: left; /* Align text (left, center, right, justify) */
  color: #333; /* Set text color */
  letter-spacing: 0.5px; /* Adjust spacing between letters */
  word-spacing: 1px; /* Adjust spacing between words */
  text-decoration: none; /* Options: underline, overline, line-through */
  white-space: normal; /* Control text wrapping (nowrap, pre, pre-wrap) */
  margin: 0; /* Set margin */
}
