/* General styles for the header */
.navbar {
  position: fixed;
  top: 0px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  z-index: 50;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}

.navbar .container {
  max-width: 112rem; /* 7xl */
  margin: 0 auto;
  padding: 0 1rem;
}

.navbar .header-content {
  display: flex;
  justify-content: space-between;
  height: 4rem;
  align-items: center;
}

.navbar .logo {
  font-weight: bold;
  font-size: 1.5rem;
  color: #4c51bf; /* Indigo color */
  cursor: pointer;
}

.navbar .logo:hover {
  transform: scale(1.05);
}

.navbar .nav-links {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.navbar .nav-links a {
  color: #4a5568; /* Gray */
  text-decoration: none;
}

.navbar .nav-links a:hover {
  color: #4c51bf; /* Indigo */
  transform: scale(1.05);
}

/* Login and Sign-up buttons */
.navbar .nav-buttons button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem; /* Rounded */
  transition: all 0.2s;
}

.navbar .nav-buttons .signin {
  color: #4a5568; /* Gray */
  border: 1px solid #e2e8f0;
}

.navbar .nav-buttons .signin:hover {
  color: #4c51bf; /* Indigo */
}

.navbar .nav-buttons .signup {
  background-color: #4c51bf; /* Indigo */
  color: white;
  border: none;
}

.navbar .nav-buttons .signup:hover {
  background-color: #434190; /* Darker Indigo */
}

/* Mobile Menu */
.navbar .menu-icon {
  display: none;
}

.navbar .mobile-menu {
  display: none;
}

.navbar .mobile-menu.open {
  display: block;
  background-color: rgba(255, 255, 255, 0.9);
}

.navbar .mobile-menu .menu-item {
  display: block;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: #4a5568; /* Gray */
  text-decoration: none;
  transition: background-color 0.2s;
}

.navbar .mobile-menu .menu-item:hover {
  background-color: #f3f4f6; /* Light Gray */
  color: #4c51bf; /* Indigo */
}

/* Desktop view */
@media (min-width: 768px) {
  .navbar .menu-icon {
    display: none;
  }

  .navbar .mobile-menu {
    display: none;
  }

  .navbar .nav-links {
    display: flex;
    gap: 2rem;
  }
}
.nav-links-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Mobile view */
@media (max-width: 820px) {
  .nav-links-buttons {
    display: none;
  }
  .navbar .menu-icon {
    display: block;
    cursor: pointer;
  }

  .navbar .mobile-menu {
    display: none;
  }

  .navbar .mobile-menu.open {
    display: block;
  }

  .navbar .mobile-menu .menu-item {
    font-size: 1.2rem;
    padding: 1rem 2rem;
  }
}
