@import url("https://fonts.googleapis.com/css2?family=Agu+Display&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Space+Grotesk:wght@300..700&display=swap");

.contentsize {
  width: 65%;
  margin: 0 auto;
  font-family: "Space Grotesk", serif;
  display: "flex";
  flex-direction: "column";
  gap: "30px";
}

@media screen and (max-width: 800px) {
  .contentsize {
    width: 100%;
  }
}

.bannerz {
  display: flex;
  flex-direction: column;
}

.bannerz h1 {
  font-size: 2rem;
  text-align: center;
  margin: 20px 0;
}

.bannerz img {
  max-width: 90%;
  max-height: 500px;
  margin: 0 auto;
  cursor: pointer; /* Indicate the image is clickable */
}
.invitation_video {
  width: 100%;
  height: auto;
}
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal img {
  max-width: 90%;
  max-height: 90%;
}

.modal.show {
  display: flex;
}

.timings {
  font-size: medium;
  display: flex;
  margin: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-direction: column;
}

.timings p {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping of time values on smaller screens */
  gap: 20px; /* Space between time values */
  font-size: larger;
  justify-content: center; /* Ensure the content stays centered */
  text-align: center; /* Center the text */
}

.timings span {
  display: inline-block; /* Ensure each span is treated as an inline block */
}
.videoContainer {
  width: 760px;
  height: 450px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 70px;
  box-sizing: border-box;
}

.video-container-hlsplayer {
  position: relative; /* Ensure the container is positioned relative */
  background-image: url("../../../assets/images/image_2_jmzdab.jpg");
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  width: 100%; /* Ensure the container takes full width */
  height: 100%; /* Ensure the container takes full height */
}

.video-container-hlsplayer .loading-overlay {
  position: absolute; /* Position absolutely within the container */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; /* Make the overlay take the entire container size */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  z-index: 10; /* Make sure the loading view is on top of the video */
}

.video-container-hlsplayer .hls-player-wrapper {
  position: absolute; /* Position it absolutely to fit within the container */
  top: 0;
  left: 0;
  width: 100%; /* Ensure the video takes full width */
  height: 100%; /* Ensure the video takes full height */
  display: flex;
  justify-content: center;
  align-items: center; /* Center the video player */
}

.video-container-hlsplayer .hls-player-styles {
  width: 100%; /* Ensure the video takes full width */
  height: 100%; /* Ensure the video takes full height */
}

.videoContainerForHigherHight {
  width: 700px;
  height: 900px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 70px;
  box-sizing: border-box;
  transform: rotate(90deg);
  transform-origin: center;
}
