.MuiFormControl-root {
  width: 100% !important;
  height: 56px !important;
}

.MuiFormHelperText-root {
  color: var(--red) !important;
  font: normal 400 14px/24px "Regular", sans-serif !important;
  margin-top: 5px !important;
  margin-bottom: 10px !important;
}

.react-tel-input .form-control {
  width: 400px !important;
  height: 56px !important;
}

@media screen and (max-width: 768px) {
  .react-tel-input .form-control {
    width: 100% !important;
  }
}
