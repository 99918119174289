.mainContainerStyle {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  height: 100vh;
  /* background-image: url("../../../../assets/images/eventsMainBgImg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  background: linear-gradient(to bottom right, #ebf4ff, #ffffff, #f3e8ff);
}

.titleStyle {
  font: normal 300 36px/26px "Medium", sans-serif;
  color: var(--white);
}

.rightBlockStyle {
  width: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  background-color: var(--white);
  padding: 24px;
  border-radius: 6px;
  border: 2px solid #d4d0d0;
}

.eventsTitleStyle {
  font-family: "Lobster", cursive;
  font-size: 3rem;
  color: #0ff;
  text-shadow: 0 0 5px #00f, 0 0 10px #0ff, 0 0 20px #0ff, 0 0 30px #00f;
  align-self: self-start;
}

.loginInfoStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: start;
}

.bottomBlockStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}
.imageBlockStyle {
  width: 500px;
  height: 400px;
}

.imageStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.inputBlockStyle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.inputStyle {
  max-width: 100%;
  width: 400px;
  height: 40px;
}

.contactBlockStyle {
  display: flex;
  gap: 20px;
}

.contactStyle {
  width: 300px !important;
}

.checkBoxBlockStyle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.loginTextStyle {
  font: normal 500 24px/24px "Medium", sans-serif;
}
.backButton {
  align-self: flex-start;
  color: black;
  padding: 10px 15px;
  border-radius: 6px;
  border: none;
  font-size: 1.5rem;
}
.backButton:hover {
  background: gray;
}
.loginSubTextStyle,
.loginPromptTextStyle,
.loginPromptSubTextStyle {
  font: normal 300 16px/24px "Light", sans-serif;
}
.loginPromptSubTextStyle {
  text-decoration: none;
  border-bottom: 1px solid var(--blue);
  font: normal 500 16px/24px "Medium", sans-serif;
  color: var(--blue);
  cursor: pointer;
}

.signUpPromptStyle {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.backViewStytle {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.leftArrowImgStyle {
  cursor: pointer;
}
.backTextStyle {
  font: normal 400 21px/24px "Regular", sans-serif;
}

/* Media Query Start */
@media screen and (max-width: 1280px) {
  .mainContainerStyle {
    max-width: 1280px;
    padding: 24px;
  }

  .imageBlockStyle {
    width: 300px;
    height: 300px;
  }
  .inputStyle {
    width: 300px;
  }
}

@media screen and (max-width: 1024px) {
  .imageBlockStyle {
    width: 300px;
    height: 300px;
  }
}

@media screen and (max-width: 768px) {
  .mainContainerStyle {
    gap: 24px;
  }
  .imageBlockStyle {
    display: none;
  }

  .titleStyle {
    font: normal 300 24px/24px "Light", sans-serif;
  }
  .inputBlockStyle {
    gap: 16px;
  }
}

@media screen and (max-width: 530px) {
  .bottomBlockStyle,
  .rightBlockStyle,
  .inputStyle {
    width: 100%;
  }
  .contactStyle {
    width: 300px;
  }
}

@media screen and (max-width: 430px) {
  .mainContainerStyle {
    gap: 16px;
  }

  .inputStyle {
    width: 100%;
  }
}

/* Media Query End */
