.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  background: #ffffff;
  height: 80px;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 2px 40px;
  position: sticky;
  top: 0;
  z-index: 2;
}

.weddingLogoStyle {
  font-family: "Great Vibes", cursive;
  font-size: 40px;
  color: #e94e77;
  /* color: #2f2f2f; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
}

/* .navLogo:hover {
  color: #e94e77;
} */

.SakuraIcon {
  width: "30px";
  height: 20px;
}

.menu {
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap: 20px; */
  padding: 10px;
}

.menuItemStyle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 200px;
  padding: 14px;
}

.menuIcon {
  font-size: 20px;
  /* color: #e94e77; */
}

.navLinkTextStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  padding: 10px;
  text-decoration: none;
  font: normal 16px/24px "Medium", sans-serif !important;
  text-transform: uppercase;
  color: #2f2f2f;
}
.navLinkTextStyle:hover {
  color: #e94e77;
}

.menuIconToggle {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .navbar {
    justify-content: space-between;
  }

  .navLogo,
  .menuIconToggle {
    padding: 0 20px;
  }

  .menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 0px;
    right: 0px;
    background: #f4f4f4;
    width: 100%;
    height: 100vh;
  }

  .menu.open {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 40px;
  }

  .menuItemStyle {
    gap: 14px;
  }

  .navLinkTextStyle {
    font: normal 20px/24px "Regular", sans-serif !important;
  }

  .menuIcon {
    font-size: 24px;
  }
  .menuCloseIcon {
    position: absolute;
    top: 40px;
    right: 40px;
    font: normal 30px/24px "Regular", sans-serif !important;
    border: 2px solid #e94e77;
    border-radius: 20%;
    padding: 2px 5px;
  }
  .menu p {
    padding: 5px;
    color: #2f2f2f;
  }
  .menu p:hover {
    color: #e94e77;
  }

  .menuIconToggle {
    display: block;
  }
  .weddingLogoStyle {
    font-size: 30px;
  }
}

@media (min-width: 769px) {
  .menuCloseIcon {
    display: none;
  }
}
@media (max-width: 500px) {
  .weddingLogoStyle {
    font-size: 20px;
  }
}

/* styles.css */
