.hlsPlayerBlock {
  position: relative;
}

.hlsPlayerStyles {
  width: 80%;
  height: 100%;
}

.errorText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.4rem;
}
.videoContainer {
  width: 760px;
  height: 450px;
}

@media (max-width: 900px) {
  .videoContainer {
    width: 700px;
    height: 400px;
  }
}

@media (max-width: 768px) {
  .videoContainer {
    width: 600px;
    height: 400px;
  }
}

@media (max-width: 600px) {
  .videoContainer {
    width: 400px;
    height: 300px;
  }
}
@media (max-width: 400px) {
  .videoContainer {
    width: 100%;
    height: 300px;
  }
}

.hidden {
  display: none;
}
