.MuiInputBase-root-MuiTablePagination-select {
  display: none;
}

.MuiTablePagination-input {
  display: none !important;
}

.MuiTablePagination-selectLabel {
  display: none !important;
}

.MuiTableCell-head {
  font: normal 18px/24px "Regular", sans-serif !important;
}

.MuiTableCell-body {
  font: normal 16px/24px "Medium", sans-serif !important;
}

.updateHlsLiveBtn {
  width: 200px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.updateHlsLiveBtn:hover {
  background-color: #0056b3;
}

.updateHlsLiveBtn:active {
  background-color: #004085;
  transform: scale(0.98);
}

.updateHlsLiveBtn:focus {
  outline: none;
}
