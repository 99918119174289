.menuItemBlock {
  display: flex;
  align-items: center;
  gap: 5px;
}

.uploadVideoTextStyle {
  font: normal 16px/24px "Regular", sans-serif !important;
}

@media (max-width: 768px) {
}

.uploadLiveVideoBtn {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.uploadLiveVideoBtn:hover {
  background-color: "#45a049";
}
.creditCount {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
}

/* .cardContainer {
  display: flex;
  gap: 10px;
  justify-content: center;
  justify-content: space-around;
  padding: 10px;
}

.card {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  color: #ffffff;
  flex-direction: row;
  gap: 10px;
  border-radius: 5px;
  padding: 10px 20px;
  min-width: 150px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; 
}
.cardPara {
  text-align: left;
  font-size: 37px;
  font-family: auto;
  font-weight: 800;
}
@media (max-width: 450px) {
  .card {
    padding: 10px 10px;
    gap: 5px;
    min-width: 100px;
    font-size: 10px;
  }
  .cardPara {
    font-size: 12px;
  }
  .cardIcon {
    font-size: 18px;
  }
}

.card:hover {
  transform: translateY(-5px); 
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
} */
