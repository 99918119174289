.templateGlobal {
  /* background: linear-gradient(
    -45deg,
    #ff7f50,
    #6a5acd,
    #48d1cc,
    #ffd700
  ) !important; */
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  padding: 20px 0;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.templateTransitionGlobal {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fadeIn {
  opacity: 1;
}

.fadeOut {
  opacity: 0;
}

.banner_block {
  width: 100%;
  height: 300px;
  padding: 5px;
  overflow: hidden;
  position: relative;
}
.banner_image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
}

/* Venue Section Styles */
.venueBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin: auto;
}

.venueContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.venueHeading,
.liveVideoHeading,
.frameContent,
.galleryHeading {
  font-family: "Great Vibes", cursive;
  font-size: 40px;
}
.venueDate,
.venueTime,
.venuePlace {
  font-size: 26px;
  font-style: italic;
  position: relative;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 8px;
}
/* .venueDate::before {
  content: "";
  background-color: #b19a56;
  width: 80px;
  height: 1px;
  position: absolute;
  left: 30px;
  top: 50%;
}
.venueDate::after {
  content: "";
  background-color: #b19a56;
  width: 80px;
  height: 1px;
  position: absolute;
  right: 30px;
  top: 50%;
} */

/* Invitation Section Styles */

.invitationHeading,
.footerHeading {
  font-family: "Great Vibes", cursive;
  font-size: 50px;
  margin-top: 40px;
  font-weight: 800;
}

.invitation_container {
  width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px;
  /* border: 1px solid green; */
}

.invitation_media_block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.invitationFrame {
  background-image: url("../../assets/images/image_1_fmclnk.jpg");
  height: 450px;
  width: 760px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 70px;
  box-sizing: border-box;
}

.timeDateWedding {
  margin: auto;
  width: 200px;
  margin-bottom: 20px;
  font-size: 14px;
}
.marginBottom {
  margin-bottom: 20px;
}
.invitaitonImage {
  border: 30px white solid;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  height: 500px;
  width: auto;
  transition: transform 0.3s ease-in-out;
  object-fit: cover;
  cursor: pointer;
}
.invitaitonImage:hover {
  transform: scale(1.01);
}
@media (max-width: 600px) {
  .marginBottom {
    margin-bottom: 10px;
  }
  .timeDateWedding {
    margin: auto;
    width: 100px;
    margin-bottom: 10px;
    font-size: 12px;
  }
}
@media (max-width: 400px) {
  .timeDateWedding {
    font-size: 10px;
  }
}
.caption {
  font-size: 0.95rem !important;
  margin-top: 18px !important;
}
.invitationTemplate {
  font-size: 30px;
}
.invitationCaption {
  font-size: 20px;
}
@media (max-width: 1024px) {
  .invitationFrame {
    height: 400px;
    padding: 50px;
    font-size: 0.85rem !important;
  }
  .invitationTemplate {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .invitationFrame {
    height: 250px;
    padding: 30px;
    font-size: 0.5rem !important;
  }
  .invitationTemplate {
    font-size: 20px;
  }
  .invitationCaption {
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  .invitationFrame {
    height: 200px;
    padding: 0px;
    font-size: 0.4rem !important;
  }
  .caption {
    font-size: 0.65rem !important;
    margin-top: 4px;
  }
  .hrStyling {
    height: 100%;
    margin: 0px 10px;
  }
  .invitationTemplate {
    font-size: 15px;
  }
}

@media (max-width: 360px) {
  .invitationFrame {
    height: 180px;
    padding: 15px;
    font-size: 0.3rem !important;
  }
  .caption {
    font-size: 0.3rem;
  }
  .invitationTemplate {
    font-size: 10px;
  }
  .invitationCaption {
    font-size: 8px;
  }
}
.videoContainerIframe {
  max-width: 1020px;
  width: 90%;
  height: 550px;
  border: 30px white solid;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
  object-fit: cover;
  /* background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 70px;
  box-sizing: border-box; */
}
.videoContainer {
  width: 760px;
  height: auto;
}

.videoContainerPortrait {
  width: 450px;
  height: 760px;
}

.video-container-hlsplayer {
  position: relative; /* Ensure the container is positioned relative */
  background-image: url("../../assets/images/image_2_jmzdab.jpg");
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  width: 100%; /* Ensure the container takes full width */
  height: 100%; /* Ensure the container takes full height */
}

.video-container-hlsplayer .loading-overlay {
  position: absolute; /* Position absolutely within the container */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; /* Make the overlay take the entire container size */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  z-index: 10; /* Make sure the loading view is on top of the video */
}

.video-container-hlsplayer .hls-player-wrapper {
  position: absolute; /* Position it absolutely to fit within the container */
  top: 0;
  left: 0;
  width: 100%; /* Ensure the video takes full width */
  height: 100%; /* Ensure the video takes full height */
  display: flex;
  justify-content: center;
  align-items: center; /* Center the video player */
}

.video-container-hlsplayer .hls-player-styles {
  width: 100%; /* Ensure the video takes full width */
  height: 100%; /* Ensure the video takes full height */
}

.videoContainerForHigherHight {
  width: 700px;
  /* height: 900px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 70px;
  box-sizing: border-box;
  transform-origin: center; */
}
.invitationVideoBlockForHigherHeight {
  width: 50%;
}
.timings {
  font-size: medium;
  display: flex;
  margin: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-direction: column;
}

.timings p {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping of time values on smaller screens */
  gap: 20px; /* Space between time values */
  font-size: larger;
  justify-content: center; /* Ensure the content stays centered */
  text-align: center; /* Center the text */
}

.timings span {
  display: inline-block; /* Ensure each span is treated as an inline block */
}

.bannerz {
  display: flex;
  flex-direction: column;
}

.bannerz h1 {
  font-size: 2rem;
  text-align: center;
  margin: 20px 0;
}

.bannerz img {
  max-width: 90%;
  max-height: 500px;
  margin: 0 auto;
  cursor: pointer; /* Indicate the image is clickable */
}
.invitation_card_img {
  border-radius: 10px;
  max-height: 300px;
}

@media (max-width: 900px) {
  .invitationFrame,
  .videoContainer,
  .videoContainerIframe {
    width: 700px !important;
    height: auto;
  }
  .videoContainerPortrait {
    width: 400px !important;
    height: 700px !important;
  }
  .invitaitonImage {
    height: 250px;
  }
  .invitationHeading {
    font-size: 30px;
  }
  .advertismentP {
    width: 700px !important;
  }
  .advertismentL {
    width: 400px !important;
  }
  .invitation_card_img {
    width: 200px;
    height: auto;
  }
}

@media (max-width: 768px) {
  .invitationFrame {
    width: 600px !important;
    height: 400px !important;
    background-size: contain;
  }
  .videoContainer,
  .videoContainerIframe {
    width: 600px !important;
    height: auto;
  }
  .videoContainerPortrait {
    width: 400px !important;
    height: 600px !important;
    margin-bottom: 90px;
  }
  .invitationHeading {
    font-size: 26px;
  }
  .invitaitonImage {
    height: 220px;
  }
  .advertismentL {
    width: 400px !important;
  }
  .advertismentP {
    width: 600px !important;
  }
  .invitation_card_img {
    width: 200px;
    height: auto;
  }
}

@media (max-width: 600px) {
  .invitationFrame,
  .videoContainer,
  .videoContainerIframe {
    width: 400px !important;
    height: auto;
  }
  .videoContainerPortrait {
    margin-bottom: 100px;
    width: 300px !important;
    height: 400px !important;
  }
  .invitaitonImage {
    height: 200px;
  }
  .invitationHeading {
    font-size: 22px;
  }
  .advertismentP {
    width: 400px !important;
  }

  .advertismentL {
    width: 300px !important;
  }
}

@media (max-width: 430px) {
  .invitationFrame {
    background-size: contain;
    width: 320px !important;
    height: 260px !important;
  }
  .invitation_card_img {
    width: 170px;
    height: auto;
    background-size: cover;
  }
  .videoContainerPortrait {
    width: 260px !important;
    height: 360px !important;
  }
  .invitaitonImage {
    height: 180px;
  }
  .invitationHeading {
    font-size: 20px;
  }

  .advertismentP {
    width: 360px !important;
  }
  .advertismentL {
    width: 260px !important;
  }
}
@media (max-width: 300px) {
  .invitationFrame {
    background-size: contain;
    width: 180px !important;
    height: 150px !important;
  }
  .invitation_card_img {
    width: 150px;
    height: auto;
    background-size: cover;
  }
  .videoContainer,
  .videoContainerIframe {
    width: 180px !important;
  }
  .videoContainerPortrait {
    width: 180px !important;
    height: 80px !important;
  }
  .invitaitonImage {
    height: 180px;
  }
  .invitationHeading {
    font-size: 20px;
  }

  .advertismentP {
    width: 360px !important;
  }
  .advertismentL {
    width: 260px !important;
  }
}

@media (max-width: 330px) {
  .invitationFrame {
    width: 300px !important;
    height: 240px !important;
  }
  .invitation_card_img {
    width: 100px;
    height: 150px;
  }
}

.invitationImageBlock {
  /* height: 800px;
  max-width: 800px; */
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.invitationVideoBlock {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.offline_video_block {
  width: 97%;
  /* height: 100%; */
}

.youtube_recorded_block,
.youtube_live_block {
  width: 100%;
  height: 100%;
}

.youtube_recorded,
.youtube_live {
  width: 100%;
  height: 100%;
  border: none;
}

.galleryBlock {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

/* Footer Styles */

.frameContainer {
  width: 500px;
  position: relative;
  padding: 10px;
  /* border: 1px solid green; */
}
.frameImage {
  width: 100%;
}
.frameContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: -20px 0 0 20px;
  display: block;
}

/* Modal overlay */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content */
.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.modalTitle {
  font: normal 18px/24px "Regular", sans-serif !important;
}

.modalSubTitle {
  font: normal 16px/24px "Regular", sans-serif !important;
  color: green;
}

.inputField {
  width: 100%;
  padding: 10px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.errorMessage {
  color: red;
  font-size: 14px;
  margin-bottom: 12px;
  font: normal 14px/24px "Regular", sans-serif !important;
}

.submitBtn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submitBtn {
  background-color: #f44336;
  color: white;
}

.submitBtn:hover {
  background-color: #d32f2f;
}

.invatationBgImg {
  background-image: url("https://res.cloudinary.com/dmhx6frzp/image/upload/v1728386913/145_1_gcqo2p.jpg");
  height: 800px;
  width: 400px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.date {
  font-weight: 600;
  border-top: 2px solid;
  border-bottom: 2px solid;
  margin: 8px;
  padding: 5px;
}

@media (max-width: 900px) {
  .invitation_container {
    width: 700px;
  }
  /*.invitationVideoBlock {
    width: 700px;
    height: 400px;
  } */
  /* .youtube_recorded_block,
  .youtube_live_block {
    width: 700px;
    height: 400px;
  } */
  /* .offline_video_block {
    width: 700px;
  } */
  .frameContainer {
    width: 400px;
  }
  .invitationHeading,
  .footerHeading {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  /* .invitation_container {
    width: 600px;
  } */
  /* .invitationVideoBlock {
    width: 600px;
    height: 400px;
  } */
  /* .youtube_recorded_block,
  .youtube_live_block {
    width: 600px;
    height: 400px;
  } */
  /* .offline_video_block {
    width: 600px;
  } */
  .frameContainer {
    width: 350px;
  }
  /* .invitationVideo {
    max-width: 500px;
    height: 600px;
    object-fit: cover;
  } */
}

@media (max-width: 600px) {
  .invitation_container {
    width: 400px;
  }
  /* .invitationVideoBlock {
    width: 400px;
    height: 300px;
  } */
  /* .youtube_recorded_block,
  .youtube_live_block {
    width: 400px;
    height: 300px;
  } */
  /* .offline_video_block {
    width: 400px;
  } */
  .frameContainer {
    width: 300px;
  }
  .invitationHeading,
  .footerHeading {
    font-size: 22px;
  }
}

@media (max-width: 430px) {
  .invitation_container {
    width: 350px;
  }
  .invitationVideoBlock {
    height: 70%;
  }
  .youtube_recorded_block,
  .youtube_live_block {
    height: 70%;
  }
  .offline_video_block {
    height: 70%;
  }
}

@media (max-width: 330px) {
  .invitation_container {
    width: 320px;
  }
  /* .invitationVideoBlock {
    width: 100%;
    height: 220px;
  } */
  /* .youtube_recorded_block,
  .youtube_live_block {
    width: 350px;
  } */
  /* .offline_video_block {
    width: 350px;
  } */
}

.advertismentP {
  width: 760px;
}

.advertisementContainer {
  width: 90% !important; /* Apply width globally */
}
.advertismentL {
  width: 450px;
}

.advertisementContainer {
  margin: auto;
  background-color: #f2f2f2;
  padding: 10px 0;
  overflow: hidden;
  white-space: nowrap;
}

.advertisement {
  white-space: nowrap;
  display: inline-block;
  animation: scrollAd 20s linear infinite;
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

@keyframes scrollAd {
  0% {
    transform: translateX(100%); /* Start from right */
  }
  100% {
    transform: translateX(-100%); /* End at left */
  }
}

.mainContainerForPage {
  min-width: 380px;
  opacity: 0;
  animation: fadeIn 5s forwards;
}
@keyframes fadeIn {
  10% {
    opacity: 0.1;
  }
  20% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.6;
  }
  80% {
    opacity: 0.7;
  }
  90% {
    opacity: 0.8;
  }
  95% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}
/* Main content styling */
.mainContent {
  padding: 20px;
  font-size: 18px;
}

.loaderContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  animation: fadeInOut 4s forwards;
}
@keyframes fadeInOut {
  10% {
    opacity: 1;
  }
  20% {
    opacity: 0.9;
  }
  30% {
    opacity: 0.8;
  }
  40% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.6;
  }
  60% {
    opacity: 0.5;
  }
  80% {
    opacity: 0.4;
  }
  90% {
    opacity: 0.3;
  }
  95% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 100px;
  aspect-ratio: 1;
  background: linear-gradient(45deg, #60b99a 50%, #0000 0),
    linear-gradient(45deg, #0000 50%, #60b99a 0),
    linear-gradient(-45deg, #f77825 50%, #0000 0),
    linear-gradient(-45deg, #0000 50%, #f77825 0), linear-gradient(#554236 0 0);
  background-size: 50% 50%;
  background-repeat: no-repeat;
  animation: l18 1.5s infinite;
}
@keyframes l18 {
  0% {
    background-position: 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%;
  }
  25% {
    background-position: 0 100%, 100% 0, 50% 50%, 50% 50%, 50% 50%;
  }
  50% {
    background-position: 0 100%, 100% 0, 100% 100%, 0 0, 50% 50%;
  }
  75% {
    background-position: 50% 50%, 50% 50%, 100% 100%, 0 0, 50% 50%;
  }
  100% {
    background-position: 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%;
  }
}
.invitationPopOver {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.carouselContainer {
  position: relative;
  margin: 0 auto;
  text-align: center;
}

/* .bannerItem {
  text-align: center;
} */

.bannerImage {
  max-width: 800px;
  width: 100%;
  max-height: 100px;
  border-radius: 8px;
}

.prevButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.prevButton {
  left: 10px;
}

.nextButton {
  right: 10px;
}
