.galleryContainer {
  max-width: 800px;
  position: relative;
  margin: auto;
  padding: 10px 0px;
}

.slider {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageContainer {
  padding: 10px;
}

.thumbnail {
  width: 100%;
  height: 300px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 5px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Ensure the overlay covers the full width */
  height: 100vh; /* Ensure the overlay covers the full height */
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.fullImage {
  max-width: 90%; /* Ensure the image fits within 90% of the overlay's width */
  max-height: 90%; /* Ensure the image fits within 90% of the overlay's height */
  object-fit: contain; /* Maintain aspect ratio and show the full image */
}

.nextArrow {
  display: block !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  font-size: 2rem;
  color: black;
  right: -20px;
  background-color: rgb(209, 207, 207);
  border-radius: 50%;
}

.prevArrow {
  display: block !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  font-size: 2rem;
  color: black;
  left: -20px;
  background-color: rgb(209, 207, 207);
  border-radius: 50%;
}
/* 
.nextArrow,
.prevArrow {
  transition: transform 0.3s ease-in-out;
}

.nextArrow:hover,
.prevArrow:hover {
  transform: scale(1.2);
} */
