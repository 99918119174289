.inputStyle {
  display: none;
}

.labelTextStyle {
  color: var(--blue9E);
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-bottom: 1px solid var(--blue9E);
  cursor: pointer;
}

.uploadIconBlockStyle {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}

.uploadIconStyle {
  width: 24px;
  height: 24px;
}

.imageStyle {
  width: 100%;
  height: 100%;
}
