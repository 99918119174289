.resetFormContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    /* background-color: #007bff; */
    background-color: #4c92dd;
}

.resetFormContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  max-width: 800px;
  padding: 40px 30px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: auto;
}

.inputFieldWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

}

.message {
  color: #d32f2f;
  text-align: center;
}

.resetFormBtn {
  background-color: #007bff;
  color: #fff;
  width: 100%;
  padding: 12px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.resetFormBtn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.inputField {
  width: 100%;
}

.loader {
  color: #fff;
  margin-left: 10px;
}
