.sidebarContainerStyle {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  padding: 16px;
  height: auto;
}

.topSectionStyle {
  font: normal 500 24px/24px "Medium", sans-serif;
}

.bottomSectionStytle {
  display: flex;
  gap: 16px;
}

.menuItemStyle,
.activeMenuItemStyle {
  display: flex;
  gap: 10px;
  padding: 10px;
  align-items: center;
  cursor: pointer;
  color: #4a5568;
}

.activeMenuItemStyle {
  color: #4a5568;
  border-radius: 6px;
  color: rgb(52, 52, 249);
}

.menuIconStyle {
  width: 26px;
  height: 26px;
}

.menuItemTextStyle {
  font: normal 500 16px/24px "Medium", sans-serif;
}

/* Hide the sidebar below 800px */
@media (max-width: 800px) {
  .sidebarContainerStyle {
    display: none;
  }
}
