.maincomp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
  gap: 24px;
  padding: 20px;
  width: 100%;
  min-width: 300px;
  position: absolute;
}

.cardmaincomp {
  width: 100%;
  max-width: 768px;
  display: flex;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: nowrap; /* Prevent wrapping by default */
  height: auto; /* Set consistent height for all cards */
}

@media (max-width: 500px) {
  .cardmaincomp {
    flex-wrap: wrap; /* Allow cards to wrap on smaller screens */
  }
}

.mainContainerForPage {
  opacity: 0;
  animation: fadeIn 5s forwards;
}
@keyframes fadeIn {
  10% {
    opacity: 0.1;
  }
  20% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.6;
  }
  80% {
    opacity: 0.7;
  }
  90% {
    opacity: 0.8;
  }
  95% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

.loaderContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  animation: fadeInOut 4s forwards;
}
@keyframes fadeInOut {
  10% {
    opacity: 1;
  }
  20% {
    opacity: 0.9;
  }
  30% {
    opacity: 0.8;
  }
  40% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.6;
  }
  60% {
    opacity: 0.5;
  }
  80% {
    opacity: 0.4;
  }
  90% {
    opacity: 0.3;
  }
  95% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 100px;
  aspect-ratio: 1;
  background: linear-gradient(45deg, #60b99a 50%, #0000 0),
    linear-gradient(45deg, #0000 50%, #60b99a 0),
    linear-gradient(-45deg, #f77825 50%, #0000 0),
    linear-gradient(-45deg, #0000 50%, #f77825 0), linear-gradient(#554236 0 0);
  background-size: 50% 50%;
  background-repeat: no-repeat;
  animation: l18 1.5s infinite;
}
@keyframes l18 {
  0% {
    background-position: 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%;
  }
  25% {
    background-position: 0 100%, 100% 0, 50% 50%, 50% 50%, 50% 50%;
  }
  50% {
    background-position: 0 100%, 100% 0, 100% 100%, 0 0, 50% 50%;
  }
  75% {
    background-position: 50% 50%, 50% 50%, 100% 100%, 0 0, 50% 50%;
  }
  100% {
    background-position: 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%;
  }
}

.cardContainer {
  display: flex;
  gap: 10px;
  justify-content: center;
  justify-content: space-around;
  padding: 10px;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px; /* Size of the circular background */
  height: 60px; /* Size of the circular background */
  background-color: rgba(
    255,
    255,
    255,
    0.2
  ); /* Semi-transparent white background */
  border-radius: 50%; /* Circular shape */
  margin-right: 15px; /* Space between icon and text */
}

.cardIcon {
  font-size: 30px; /* Icon size */
  color: #ffffff; /* Icon color */
}
.card {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  color: #ffffff; /* White text */
  flex-direction: row;
  gap: 10px;
  border-radius: 5px;
  padding: 10px 20px;
  min-width: 150px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
}
.cardPara {
  text-align: left;
  font-size: 37px;
  font-family: auto;
  font-weight: 800;
}
@media (max-width: 450px) {
  .card {
    padding: 10px 10px;
    gap: 5px;
    min-width: 100px;
    font-size: 10px;
  }
  .cardPara {
    font-size: 12px;
  }
  .cardIcon {
    font-size: 18px;
  }
}

.card:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

/* 
 
    
    
    
    */
