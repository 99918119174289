.page {
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex-wrap: wrap;
  gap: 14px;
  width: 600px;
  border: 1px solid gray;
  border-radius: 5px;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 18px;
}

.dropdownContainer {
  width: 600px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}

.selectLabel {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.passwordContainer {
  display: flex;
  flex-direction: column;
}

.passwordContainer input {
  margin-bottom: 5px;
}

.passwordContainer button {
  margin-top: 10px;
}

.error {
  color: red;
  font-size: 12px;
}

.success {
  color: green;
  font-size: 12px;
}



@media (min-width: 600px) {
  .page {
    flex-direction: row;
    align-items: center;
  }

  .checkboxLabel,
  .selectLabel,
  .passwordContainer {
    margin-right: 10px;
    margin-bottom: 0;
  }

  .dropdownContainer {
    flex-direction: row;
    align-items: center;
  }

  .passwordContainer {
    flex-direction: column;
  }
}
