.notFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.notFoundTitle {
  font-size: 6rem;
  color: transparent;
  background-image: linear-gradient(90deg, #ff416c, #ff4b2b);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.notFoundSubtitle {
  font-size: 2rem;
  color: #6c757d;
}

.notFoundDescription {
  font-size: 1.2rem;
  color: #6c757d;
  margin-bottom: 2rem;
}

.notFoundLink {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 18px;
  color: #ffffff;
  background-color: #0056b3;
  text-decoration: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 8px;
  padding: 5px 10px;
  transition: color 0.2s ease-in-out;
}

.notFoundLink:hover {
  color: #0056b3;
  background-color: #ffb300;
}
