.userActionsOptionStyles {
  font: 500 16px/24px "Light", sans-serif;
}
.passwordInputStyle {
  width: 100%;
  padding: 10px 15px;
  outline: none;
  margin-bottom: 10px;
}
/* Dialog Title Buttons Styling */
.MuiDialogTitle-root {
  padding: 16px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
}

.dialogTitleButtons {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
}

.dialogTitleButtons button {
  padding: 8px 20px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s, transform 0.2s;
}

.dialogTitleButtons button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.dialogTitleButtons button.active {
  background-color: #28a745;
}

.dialogTitleButtons button.active:hover {
  background-color: #218838;
}

.active {
  background-color: #28a745;
  color: #ddd;
  padding: 5px 10px;
  border-radius: 5px;
}
.inactive {
  background-color: #0056b3;
  color: #ddd;
  padding: 5px 10px;
  border-radius: 5px;
}

/* Dialog Content Styling */
.MuiDialogContent-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  background-color: #fafafa;
}

.MuiTextField-root {
  margin-bottom: 15px;
  width: 100%;
}

.passwordInputStyle {
  display: flex;
  gap: 5px;
  padding: 3px 15px;
  font-size: 14px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.passwordInputStyle:focus {
  border-color: #007bff;
  outline: none;
}

/* Labels */
label {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
  align-self: flex-start;
}

/* Dialog Actions (Buttons) */
.MuiDialogActions-root {
  padding: 20px;
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.closeBtn {
  padding: 10px 20px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.2s;
}

.closeBtn:hover {
  background-color: #c82333;
  transform: scale(1.05);
}

.saveBtn {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.2s;
}

.saveBtn:hover {
  background-color: #218838;
  transform: scale(1.05);
}

/* Circular Progress Styling */
.loader {
  margin-left: 10px;
}

.editButton {
  background-color: #333;
  padding: 5px 8px;
  border: none;
  border-radius: 5px;
}
