.appContainer {
  font-family: Arial, sans-serif;
  padding: 50px 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.noDataText {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 240px);
}

.title {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font: normal 24px/24px "Regular", sans-serif;
}

.inputLabelStyle {
  font: normal 20px/24px "Medium", sans-serif;
}
.radioButton {
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.radioButton.selected {
  background-color: #4caf50;
  color: white;
}
.radioButtons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 20px;
}

.radioButtons label {
  margin: 0 15px;
  font-size: 16px;
}

.channelTable {
  width: 100%;
  border-collapse: collapse;
}

@media (max-width: 500px) {
  .channelTable {
    padding: 0;
  }
  .appContainer {
    padding: 10px;

    margin: 10px;
  }
}

.tableRowStyle {
  height: 60px; /* Fixed height for table rows */
}

.tableHeadingStyle,
.tableDataStyle {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  vertical-align: middle; /* Center align text vertically */
}

.tableHeadingStyle {
  background-color: #f2f2f2;
  font: normal 16px/24px "Medium", sans-serif;
}

.tableDataStyle {
  font: normal 16px/24px "Regular", sans-serif;
  overflow: hidden; /* Prevent overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
  white-space: nowrap; /* Prevent text wrapping */
}

.viewBtn,
.deleteBtn {
  height: 36px; /* Fixed height for buttons */
  padding: 6px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font: normal 14px/24px "Regular", sans-serif;
  display: flex; /* Center content inside buttons */
  align-items: center;
  justify-content: center;
}

.viewBtn {
  background-color: #4caf50;
}

.deleteBtn {
  background-color: #f44336;
}

.paymentStatusTable {
  width: 100%;
  border-collapse: collapse;
}

.paymentStatusTableHeading,
.paymentStatusTableData,
.statusCompletedStyle,
.statusFailedStyle,
.statusCancelledStyle {
  border: 1px solid #ddd;
  padding: 8px;
  font: normal 16px/24px "Medium", sans-serif;
  text-align: center;
}
.table {
  width: 100%;
  border-collapse: collapse;
}

.th,
.td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

.th {
  background-color: #f4f4f4;
}

.td {
  user-select: none; /* Prevents text selection */
  pointer-events: none; /* Disables clicking/selecting inside the cells */
  color: #555; /* Optionally, make the text look more like a read-only field */
}

.th {
  font-weight: bold;
}
.paymentStatusTableHeading {
  background-color: #f2f2f2;
}

.statusCompletedStyle {
  color: green;
}

.statusFailedStyle {
  color: red;
}

.statusCancelledStyle {
  color: orange;
}

.modal,
.deleteConfirm {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent,
.deleteContent {
  position: absolute; /* Allows the element to be positioned relative to its closest positioned ancestor */
  top: 50%; /* Position from the top */
  left: 50%; /* Position from the left */
  transform: translate(
    -50%,
    -50%
  ); /* Adjust the position to truly center the content */
  background-color: white; /* Optional: gives the modal a white background */
  padding: 20px; /* Optional: adds padding inside the modal */
  border-radius: 8px; /* Optional: adds rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow effect */
  max-width: 600px; /* Optional: sets a maximum width */
  width: 100%;
}

.deleteContent {
  max-width: 300px;
  width: 100%;
}

.deletePromptStyle {
  font: normal 16px/24px "Medium", sans-serif;
}

.deleteBtnStyle {
  font: normal 14px/24px "Semibold", sans-serif;
  background-color: tomato;
  color: #fff;
}

.deleteActionBtnStyle {
  font: normal 14px/24px "Semibold", sans-serif;
}

.modalContent h2 {
  margin-bottom: 15px;
  color: #333;
}

.modalContent button,
.deleteContent button {
  margin-top: 10px;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modalContent button {
  background-color: #4caf50;
  color: white;
}

.deleteContent button {
  margin-right: 10px;
}

.deleteContent button:first-child {
  background-color: #f44336;
  color: white;
}

/* Styles for the form inside the modal */
.detailsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; /* Add space below the details */
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0; /* Space between form groups */
}

.label {
  font-weight: bold; /* Style for labels */
  flex: 1; /* Allow label to take 1 unit of space */
  margin-right: 10px; /* Space between label and value */
}

.readOnly {
  flex: 2; /* Allow read-only span to take 2 units of space */
  min-height: 40px; /* Minimum height for read-only fields */
  padding: 8px;
  background-color: #f9f9f9; /* Light background for non-editable fields */
  border: 1px solid #ccc; /* Light border for separation */
  border-radius: 4px;
  color: #555; /* Text color */
  display: flex; /* Flex to allow vertical centering */
  align-items: center; /* Center vertically */
  overflow: hidden; /* Prevent overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
  white-space: nowrap; /* Prevent text wrapping */
}

.mobileTableContainer {
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  overflow-x: auto; /* Allow horizontal scrolling if necessary */
}

.mobileRow {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: white;
}

.mobileCell {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.mobileCell strong {
  color: #333;
}

/* Optional: If you want to reduce the font size on mobile */
@media (max-width: 500px) {
  .mobileCell {
    font-size: 14px; /* Adjust the font size for mobile */
  }
}
